import { useGetRequest } from '../useAxiosRequests';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { useQueryClient, UseQueryResult } from 'react-query';
import { Topics, useTopic } from '../../../../contexts/TopicContext/TopicContext';
import { useEffect } from 'react';

//---------------------------------------------------------
// Hooks
//---------------------------------------------------------

/**
 * A hook to fetch the list transfer plans of today.
 *
 * @return {UseQueryResult}
 */
const useTransferPlanListOfToday = (data = null) => {
  const queryClient = useQueryClient();
  const queryResult = useGetRequest({
    queryKey: 'transferPlanListToday',
    url: '/omp-service-tracking/transfer-plan/list/today',
    data,
    options: {
      // Don't return the entire response, the data is enough.
      select: ({ data }) => data,
    },
  });

  const { addListener } = useTopic(Topics.TRANSFER_PLAN);

  useEffect(() => {
    const refreshListOfToday = () => {
      queryClient.invalidateQueries('transferPlanListToday').then(() => queryResult.refetch());
    };
    const removeListener = addListener(refreshListOfToday);
    return () => removeListener();
  }, [addListener, queryClient, queryResult]);

  return queryResult;
};

export { useTransferPlanListOfToday };
