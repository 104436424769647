import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
// import App from './javascript/App/App';

import * as serviceWorker from './serviceWorker';

import English from './javascript/data/localization/translations/en/English';
import MissingRafTranslationsEnglish from './javascript/data/localization/translations/en/MissingRafTranslationsEnglish';
import { setTranslation } from 'raf-core-react/dist/utils/localization/Translations';

// Set the translations before processing any file that uses the translations.
// Using 'import' will cause the static translations to throw an error.
setTranslation({
  ...English,

  // Missing translations in raf-core-react?
  ...MissingRafTranslationsEnglish,
});

const renderApp = () => {
  const App = require('./javascript/App/App').default;
  ReactDOM.render(<App />, document.getElementById('root'));
};
renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
