import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from './loadingAnimation.json';
import './LoadingAsOverlay.scss';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import PropTypes from 'prop-types';

const loading = translate('loading');
const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'loading-page-svg',
  },
};

const LoadingAsOverlay = (props) => (
  <div className={'loading-as-overlay-wrapper'}>
    <Lottie
      title={loading}
      ariaRole={'img'}
      options={animationOptions}
      height={70}
      width={70}
      isClickToPauseDisabled={true}
    />
    {props.messageTranslated && (
      <span className={'loading-as-overlay-message'}>{props.messageTranslated}</span>
    )}
  </div>
);

LoadingAsOverlay.prototype = {
  /** A string that will be used as message underneath the animation. */
  messageTranslated: PropTypes.string,
};

export default LoadingAsOverlay;
