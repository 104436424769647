import './PersonCardPictureAndType.scss';

import React from 'react';
import { personPropTypes } from '../../../../model/Person';
import PropTypes from 'prop-types';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';

const { getBlockClassName, getElementClassName } = BEMClassNameUtils(
  'person-card-picture-and-type'
);

export default function PersonCardPictureAndType(props) {
  return (
    <div className={getBlockClassName()}>
      <div className={getElementClassName('type', props.personType)} />
      <img className={getElementClassName('picture')} alt={'profile'} src={props.personImageUrl} />
    </div>
  );
}

PersonCardPictureAndType.propTypes = {
  /** {@link personPropTypes.personType} */
  personType: personPropTypes.type,
  /** The url that leads to the person's profile picture. */
  personImageUrl: PropTypes.string,
};
