import { useGetRequest } from '../useAxiosRequests';
import { getOrDefaultStaleTime } from '../../config';

// ----------------------------------------
// Variables
// ----------------------------------------

/**
 * Cache the customer logo for 5 days.
 * @type {number}
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 60 * 24 * 5);

// ----------------------------------------
// Hook
// ----------------------------------------

export const useCustomerLogo = () =>
  useGetRequest({
    queryKey: 'customerLogo',
    url: 'layout/logo__white',
    options: {
      staleTime: CACHE_TIME,
      select: ({ data }) => `data:${data}`,
    },
  });
