import { useCallback, useEffect } from 'react';
// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------
/* eslint-disable-next-line no-unused-vars */
import { useQueryClient, UseQueryResult } from 'react-query';
import { useGetRequest } from '../useAxiosRequests';
import { useSelectedTransferPlanContext } from '../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { Topics, useTopic } from '../../../../contexts/TopicContext/TopicContext';
import { getOrDefaultStaleTime } from '../../config';

// ------------------------------------
// Variables functions
// ------------------------------------

const createQueryKey = (vehicleId) => ['vehicleNearLocations', vehicleId];

/**
 * Data is updated via the live events. Whenever this doesn't happen, let's keep
 * it for a an hour.
 * @type {number}
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 60);

// ------------------------------------
// Hooks
// ------------------------------------

/**
 * This hook can be plugged in to do live updates from the "vehicle near location" data.
 *
 * @param {number|string} vehicleId The vehicle's database ID.
 */
const useLiveVehicleLocationUpdate = (vehicleId) => {
  const queryClient = useQueryClient();
  const queryKey = createQueryKey(vehicleId);
  const { addListener } = useTopic(Topics.LOCATION);

  const handleNearLocations = useCallback(
    (nearLocations) => {
      queryClient.cancelQueries(queryKey).then(() => {
        const { data: currentNearLocations, ...remainingResponse } = queryClient.getQueryData(
          queryKey
        );

        queryClient.setQueryData(queryKey, {
          data: nearLocations,
          ...remainingResponse,
        });
      });
    },
    [queryClient, queryKey]
  );

  useEffect(() => {
    const listenToLocationEvents = ({ method, params: { id, locations } }) => {
      const vehicleRelatedUpdate = 'locationEvents.updateVesselLocations' === method;
      const relatesToCurrentVehicle = vehicleId === id;
      if (vehicleRelatedUpdate && relatesToCurrentVehicle) {
        handleNearLocations(locations);
      }
    };

    const removeListener = addListener(listenToLocationEvents);
    return () => removeListener();
  }, [addListener, vehicleId, handleNearLocations]);
};

/**
 * Query the locations that are close enough according to a configurable proximity.
 *
 * @return {UseQueryResult}
 */
export const useVehicleNearLocations = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const vehicleId = selectedTransferPlan?.vehicle?.id;

  useLiveVehicleLocationUpdate(vehicleId);

  return useGetRequest({
    queryKey: createQueryKey(vehicleId),
    url: `/omp-service-tracking/whereabouts/vehicleNearLocations/${vehicleId}`,
    options: {
      enabled: !!vehicleId,
      staleTime: CACHE_TIME,
      select: ({ data = [] }) => new Map(Object.entries(data)),
    },
  });
};
