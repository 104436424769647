import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BEMClassNameUtils } from 'ebo-react-component-library/dist/javascript/utils';

import IconWrapper from '../../image/icon/IconWrapper';

import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import './PageSelection.scss';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

const { getBlockClassName: bPageNav } = BEMClassNameUtils('page-selection');

const { getBlockClassName: bPageLink, getElementClassName: ePageLink } = BEMClassNameUtils(
  'page-selection-element'
);

const views = [
  { pathName: '/persons', iconName: 'persons', label: translate('header.menu.persons') },
  { pathName: '/locations', iconName: 'map-marker-alt', label: translate('header.menu.locations') },
];

//-----------------------------------------------------
// Components
//-----------------------------------------------------

const PageSelectionElement = ({ label, path, iconName }) => {
  const { hash } = useLocation();

  return (
    <div className={bPageLink()} data-testid={bPageLink()}>
      <NavLink
        to={path + hash}
        className={ePageLink('link')}
        activeClassName={ePageLink('link', 'active')}
      >
        <IconWrapper idPrefix={'pageSelection'} iconName={iconName} />
        <span className={ePageLink('label')}>{label}</span>
      </NavLink>
    </div>
  );
};

PageSelectionElement.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

const PageSelection = () => (
  <div className={bPageNav()}>
    {views.map(({ pathName, iconName, label }) => (
      <PageSelectionElement key={pathName} path={pathName} iconName={iconName} label={label} />
    ))}
  </div>
);

export default memo(PageSelection);
