import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import PersonsWhereaboutsGroup from './PersonsWhereaboutsGroup/PersonsWhereaboutsGroup';
import PersonCard from '../../person/PersonCard/PersonCard';
import './LocationsPeopleWhereabouts.scss';
import { PersonCardPlaceHolder } from './PersonCardPlaceHolder/PersonCardPlaceHolder';
import { useSelectedPerson } from '../../../contexts/SelectedPersonContext/SelectedPersonContext';
import PersonDetail from '../../person/PersonDetail/PersonDetail';
import { useActivityLocationsWithPeople } from '../../../utils/hooks/useActivityLocationsWithPeople/useActivityLocationsWithPeople';
import { useVehicleNearLocations } from '../../../data/api/hooks/useVehicleNearLocations/useVehicleNearLocations';
import { createWithVehicleNearLocationsComparator } from '../../../utils/compareWithVehicleNearLocations/compareWithVehicleNearLocations';
import { locationPropTypes } from '../../../model/Location';
import { personPropTypes } from '../../../model/Person';

// ---------------------------------------
// Variables
// ---------------------------------------
const maxShownCards = 5;
const maxShownAndPlaceholder = maxShownCards + 1;

const { getBlockClassName, getElementClassName } = BEMClassNameUtils(
  'locations-person-whereabouts'
);
const blockClassName = getBlockClassName();
const personcardContainerClassname = getElementClassName('card');

/**
 * Returns the correct number to use to show the amount of items of the array
 * @param {number} length - the amount of entries in the array of persons
 * @param {boolean} toggleCards Flag indicating if the cards are toggled.
 * @returns {number} - the new length based on toggleCards state and the original length itself
 */
const getArrayLength = (length, toggleCards) => {
  return length > maxShownAndPlaceholder && toggleCards ? maxShownCards : length;
};

// ---------------------------------------
// Component
// ---------------------------------------

/**
 * A group of people that are on a certain location.
 *
 * @param {number} indexGroup
 * @param {Location} location
 * @param {Array<Person>} persons
 * @param {Map<String, Array<number>>} vehicleNearLocations
 * @param {function} selectPerson
 * @param {function} toggleCards
 * @param {function} setLocationOfInterest
 * @param {function} onPersonCardClick
 * @return {JSX}
 */
const LocationWithPeople = ({
  indexGroup,
  location,
  persons,
  vehicleNearLocations,
  selectPerson,
  toggleCards,
  setLocationOfInterest,
  onPersonCardClick,
}) => {
  const groupKey = `${location.name}-${location.locationId}`;

  const isNearLocation =
    vehicleNearLocations.has(location.type) &&
    !!vehicleNearLocations.get(location.type).find((id) => id === location.id);

  const amountOfPeoplePresent = useMemo(
    () => ({
      total: persons.length,
      planned: persons.filter((person) => !person.unplanned).length,
    }),
    [persons]
  );

  const handlePersonClick = useCallback(
    (person) => {
      selectPerson(person);
      setLocationOfInterest(location);
    },
    [location, selectPerson, setLocationOfInterest]
  );

  return (
    <div data-cy={`peoplewhereabouts-group-${indexGroup}`}>
      <PersonsWhereaboutsGroup
        initialOpen={isNearLocation}
        location={location}
        amountOfPeoplePresent={amountOfPeoplePresent}
      >
        {persons.slice(0, getArrayLength(persons.length, toggleCards)).map((person) => (
          <div
            key={`${groupKey}_${person.name}`}
            data-cy={`peoplewhereabouts-cards-${indexGroup}`}
            className={personcardContainerClassname}
          >
            <PersonCard
              id={'locations-card-'.concat(person.tppId)}
              onPersonCardClick={handlePersonClick}
              person={person}
              showFooter={false}
              locationOfInterest={location}
            />
          </div>
        ))}
        {persons.length > maxShownAndPlaceholder && (
          <PersonCardPlaceHolder
            onPersonCardClick={onPersonCardClick}
            person={persons[maxShownAndPlaceholder]}
            remainingCardNr={persons.length - maxShownCards}
            toggleCards={toggleCards}
          />
        )}
      </PersonsWhereaboutsGroup>
    </div>
  );
};

LocationWithPeople.propTypes = {
  indexGroup: PropTypes.number.isRequired,
  location: PropTypes.shape(locationPropTypes).isRequired,
  persons: PropTypes.arrayOf(PropTypes.shape(personPropTypes)).isRequired,
  vehicleNearLocations: PropTypes.instanceOf(Map),
  selectPerson: PropTypes.func,
  toggleCards: PropTypes.bool,
  setLocationOfInterest: PropTypes.func,
  onPersonCardClick: PropTypes.func,
};

/**
 * Is the Component that shows the people that are present within each location group.
 *
 * The cards show are dependent on the amount of available entries in the array.
 * If there are more than 6, the array is sliced to show only max 5 entries + a placeholder
 * If there are less than 6, all entries are shown.
 */
export const LocationsPeopleWhereabouts = () => {
  const { selectedPerson, selectPerson, resetSelectedPerson } = useSelectedPerson();
  const [toggleCards, setToggleCards] = useState(true);
  const [locationOfInterest, setLocationOfInterest] = useState(undefined);

  const { data: vehicleNearLocations = new Map() } = useVehicleNearLocations();

  const activityLocationsWithPeople = useActivityLocationsWithPeople();

  const compareWithVehicleNearLocations = createWithVehicleNearLocationsComparator(
    vehicleNearLocations
  );
  const locationsWithPeople = [...activityLocationsWithPeople].sort((first, second) =>
    compareWithVehicleNearLocations(first.location, second.location)
  );

  const handleCloseDetail = useCallback(() => {
    resetSelectedPerson();
    setLocationOfInterest(null);
  }, [resetSelectedPerson]);

  const onPersonCardClick = useCallback(() => {
    setToggleCards(!toggleCards);
  }, [toggleCards]);

  return (
    <div>
      <div data-cy="peoplewhereabouts-component" className={blockClassName}>
        {locationsWithPeople &&
          locationsWithPeople.map((locationWithPeople, indexGroup) => (
            <LocationWithPeople
              key={`location-with-people_${locationWithPeople.location.locationId}`}
              indexGroup={indexGroup}
              location={locationWithPeople.location}
              persons={locationWithPeople.people}
              vehicleNearLocations={vehicleNearLocations}
              selectPerson={selectPerson}
              toggleCards={toggleCards}
              onPersonCardClick={onPersonCardClick}
              setLocationOfInterest={setLocationOfInterest}
            />
          ))}
      </div>
      {selectedPerson && locationOfInterest && (
        <PersonDetail
          data-cy={'person-detail'}
          person={selectedPerson}
          onClose={handleCloseDetail}
          locationOfInterest={locationOfInterest}
        />
      )}
    </div>
  );
};

export default memo(LocationsPeopleWhereabouts);
