import React, { memo, useMemo } from 'react';

import Popup from 'raf-core-react/dist/components/containers/Popup/Popup';
import ConfirmButton from 'raf-core-react/dist/components/forms/buttons/ConfirmButton/ConfirmButton';
import CancelButton from 'raf-core-react/dist/components/forms/buttons/CancelButton/CancelButton';

import { TransferPlanList } from './TransferPlanList/TransferPlanList';

import { useTransferPlanSelectionPopup } from '../../../contexts/TransferPlanSelectionPopupContext/TransferPlanSelectionPopupContext';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import './TransferPlanSelection.scss';

// -----------------------------------
// Variables
// -----------------------------------

const titleLabel = translate('transferPlan.selection.title');
const confirmLabel = translate('transferPlan.selection.confirm');

// -----------------------------------
// Component
// -----------------------------------

const TransferPlanSelectionBase = () => {
  const {
    init,
    hidden,
    transferPlanForSelection,
    setTransferPlanForSelection,
    cancelSelection,
    confirmSelection,
  } = useTransferPlanSelectionPopup();

  const actionButtons = useMemo(
    () =>
      [
        !init && (
          <CancelButton
            key={'transfer-plan-selection__cancel'}
            idPrefix={'transfer-plan-selection'}
            handleCancel={cancelSelection}
          />
        ),
        <ConfirmButton
          key={'transfer-plan-selection__confirm'}
          idPrefix={'transfer-plan-selection'}
          label={confirmLabel}
          handleConfirm={confirmSelection}
          isDisabled={!transferPlanForSelection}
        />,
      ].filter((button) => !!button),
    [init, confirmSelection, cancelSelection, transferPlanForSelection]
  );

  if (hidden) {
    return null;
  }

  return (
    <Popup
      customClassName={'transfer-plan-selection'}
      title={titleLabel}
      isHidden={hidden}
      portalParentSelector={'body'}
      actionsButtons={actionButtons}
      hasOverlay
      hasBg
      isFullScreenOnMobile
    >
      <TransferPlanList
        selectedTransferPlan={transferPlanForSelection}
        setSelectedTransferPlan={setTransferPlanForSelection}
      />
    </Popup>
  );
};

export const TransferPlanSelection = memo(TransferPlanSelectionBase);
