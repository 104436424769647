import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import WebSocketProxy from './WebSocketProxy';

// FIXME OMPTRACK-96: Use raf-core-react JsonRpcWebSocket instead.
//  Internally this class uses it's own state management for web sockets ("store.js"),
//  which holds a global variable with a counter.
//  In StrictMode, setState gets called twice to see if the function is actually pure.
//  As a result, this counter gets increased twice as much but decreased only when calling "close".
//  See https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
// import WebSocketProxy from 'raf-core-react/dist/utils/websockets/WebSocketProxy/WebSocketProxy';

// ------------------------------------
// Variables
// ------------------------------------

/**
 * @typedef WebSocketProxyWrapper
 *
 * @property {WebSocketProxy} webSocketProxy
 * @property {function(): void} reInitiate
 *
 * @see raf-core-react/dist/utils/websockets/WebSocketProxy/WebSocketProxy
 */
/**
 * @type {React.Context<WebSocketProxyWrapper>}
 */
const WebSocketContext = createContext(undefined);
WebSocketContext.displayName = 'WebSocketContext';

// ------------------------------------
// Provider
// ------------------------------------

/**
 * This provider is used so the application can use the same instance of
 * the web socket. If the hook itself is used, new web socket objects are created.
 *
 * @param {String} url The url to connect to.
 * @param {JSX} children
 */
export const WebSocketContextProvider = ({ url, children }) => {
  const [webSocketProxy, setWebSocketProxy] = useState(() => new WebSocketProxy(url, undefined));
  const { keycloak } = useKeycloak();
  const reInitiate = useCallback(() => {
    setWebSocketProxy(new WebSocketProxy(url, undefined));
  }, [url]);

  useEffect(() => () => webSocketProxy.close(), [webSocketProxy]);

  useEffect(() => {
    if (!webSocketProxy) {
      return;
    }

    webSocketProxy.authenticate(keycloak.token);
  }, [webSocketProxy, keycloak.token]);

  return (
    <WebSocketContext.Provider value={{ webSocketProxy, reInitiate }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// ------------------------------------
// Hooks
// ------------------------------------

/**
 * A wrapper hook to consume the {@link WebSocketContext}.
 *
 * @return {WebSocketProxyWrapper}
 */
export const useWebSocketContext = () => useContext(WebSocketContext);
