import { useEntityImageUri } from './useEntityImageUri';

// ------------------------------------------------
// Variables
// ------------------------------------------------

const defaultImage = `${process.env.PUBLIC_URL}/img/placeholder.svg`;

// ------------------------------------------------
// Hooks
// ------------------------------------------------

/**
 * Retrieves the photo of the vessel with the corresponding ID and converts it into a Base64 encoded String.
 * If no photo is available, a default image will be used instead.
 *
 * @param {number} vesselId
 *
 * @return {string}
 */
const useVehicleImageUri = (vesselId) => {
  return useEntityImageUri({
    entityName: 'vehicle',
    entityId: vesselId,
    defaultImage,
  });
};

export { useVehicleImageUri };
