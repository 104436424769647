import { useMemo } from 'react';
import { useGetRequest } from '../useAxiosRequests';

// ---------------------------------
// Variables
// ---------------------------------

/**
 * @typedef GatewayStatus
 * An object holding status information about current gateway connection.
 *
 * @property {boolean} supportsAutoTracking Flag indicating if the gateway supports automated tracking
 * @property {boolean} connectionLost Flag indicating if the connection is lost
 */
const initialStatus = {
  supportsAutoTracking: true,
  connectionLost: false,
};

// ---------------------------------
// Hooks
// ---------------------------------

/**
 * Get the gateway status for the given location
 */
export const useGatewayStatus = (forLocation) => {
  return useGetRequest({
    queryKey: ['gatewayStatus', forLocation?.locationId],
    url: `/omp-service-tracking/gateway/status/${forLocation?.locationId}`,
    options: {
      enabled: !!forLocation?.locationId,
      initialData: initialStatus,
      select: ({ data: gatewayStatuses = [] }) =>
        gatewayStatuses.reduce(
          (reducedGatewayStatus, gatewayStatus) => ({
            supportsAutoTracking:
              reducedGatewayStatus.supportsAutoTracking || gatewayStatus.supportsAutoTracking,
            connectionLost: reducedGatewayStatus.connectionLost && !gatewayStatus.isActive,
          }),
          initialStatus
        ),
    },
  });
};

/**
 * Return the modifiers based on the {@link GatewayStatus}
 * @return {Array<String>}
 */
export const useGatewayStatusBemModifiers = ({
  supportsAutoTracking = true,
  connectionLost = false,
} = {}) =>
  useMemo(() => (supportsAutoTracking && connectionLost ? ['connectionLost'] : []), [
    connectionLost,
    supportsAutoTracking,
  ]);
