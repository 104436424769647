import { noop } from 'raf-core-react/dist/utils/functions/noop';

/**
 * Compare the names of the locations.
 *
 * @param {Location} first The first location
 * @param {location} second The second location
 */
const compareByName = (first, second) => first.name.localeCompare(second.name);

/**
 * @typedef WithVehicleNearLocationsComparator
 * Compares the locations with the "vehicle near locations". Locations that are near a vehicle are
 * sorted before the others. Then they are sorted alphabetically.
 *
 * @param {Location} first The first location
 * @param {Location} second The second location
 * @return {1|0|-1} The comparison result
 */
/**
 * Create a comparator that for locations based on "vehicle near locations".
 *
 * @param {Map<String, Array<Number>>} vehicleNearLocations A map of locations that are "near" the vehicle.
 * @return {WithVehicleNearLocationsComparator}
 */
export const createWithVehicleNearLocationsComparator = (vehicleNearLocations) => {
  if (!vehicleNearLocations) {
    console.warn('No vehicle near locations to compare with');
    return noop;
  }

  return (first, second) => {
    const includesFirstLocation =
      vehicleNearLocations.has(first.type) &&
      !!vehicleNearLocations.get(first.type).find((id) => id === first.id);

    const includesSecondLocation =
      vehicleNearLocations.has(second.type) &&
      !!vehicleNearLocations.get(second.type).find((id) => id === second.id);

    const bothIncluded = includesFirstLocation && includesSecondLocation;
    const bothExcluded = !includesFirstLocation && !includesSecondLocation;

    if (bothIncluded || bothExcluded) {
      return compareByName(first, second);
    } else {
      return -1;
    }
  };
};
