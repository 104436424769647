import './Persons.scss';

import React, { memo, useEffect } from 'react';

import PersonCard from '../../components/person/PersonCard/PersonCard';
import PersonDetail from '../../components/person/PersonDetail/PersonDetail';
import PersonGroup from '../../components/person/PersonGroup/PersonGroup';

import {
  useTransferPlanPeopleContext,
  useTransferPlanPeopleWithLocation,
} from '../../contexts/TransferPlanPeopleContext/TransferPlanPeopleContext';
import { useSelectedPerson } from '../../contexts/SelectedPersonContext/SelectedPersonContext';
import {
  TransferState,
  translateTransferState,
} from '../../utils/getGroupedPersonsByTransferState/getGroupedPersonsByTransferState';
import { useSelectedTransferPlanContext } from '../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { DisembarkAllConfirmationContextProvider } from '../../components/header/DisembarkAllConfirmationPopup/context/DisembarkAllConfirmationContext';
import { DisembarkAllConfirmationPopup } from '../../components/header/DisembarkAllConfirmationPopup/DisembarkAllConfirmationPopup';
import { DisembarkAllMessageContext } from '../../components/header/DisembarkAllConfirmationPopup/context/DisembarkAllMessageContext';
import DisembarkAll from './DisembarkAll/DisembarkAll';
import { useQueryClient } from 'react-query';
import { useWebSocketListeners } from '../../contexts/WebSocketContext/WebSocketListenersContext';

// ---------------------------------------------
// Variables
// ---------------------------------------------

const useDisembarkAllMessage = () => {
  const {
    withLocation,
    noneOnALocationOrVehicle,
    allOnALocation,
    allOnVehicle,
    peopleScattered,
  } = useTransferPlanPeopleWithLocation();

  switch (true) {
    case noneOnALocationOrVehicle:
    case allOnALocation:
      return 'No people currently boarded';
    case allOnVehicle:
      return 'Do you want to disembark all (boarded) people?';
    case peopleScattered:
      return 'People are still present on certain location(s). Do you want to disembark all (boarded) people?';
    default:
      console.warn('Case not supported', withLocation);
      return null;
  }
};

// ---------------------------------------------
// Components
// ---------------------------------------------

const Actions = () => {
  const message = useDisembarkAllMessage();

  return (
    <DisembarkAllConfirmationContextProvider>
      <DisembarkAll />

      <DisembarkAllMessageContext.Provider value={message}>
        <DisembarkAllConfirmationPopup />
      </DisembarkAllMessageContext.Provider>
    </DisembarkAllConfirmationContextProvider>
  );
};

export const PersonsBase = () => {
  const { selectedTransferPlan, transferPlanNumber } = useSelectedTransferPlanContext();
  const { byTransferState } = useTransferPlanPeopleContext();
  const { selectedPerson, selectPerson, resetSelectedPerson } = useSelectedPerson();

  const { addListener } = useWebSocketListeners();
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetchTransferPlan = () => {
      queryClient.refetchQueries(['transferPlan', transferPlanNumber]);
    };

    // The person cards are tightly hooked to the websocket and update only when a live update comes in.
    // Let's force a refresh if anything happens to the websocket
    const removeOpenListener = addListener('open', refetchTransferPlan);
    const removeCloseListener = addListener('close', refetchTransferPlan);

    return () => {
      removeOpenListener();
      removeCloseListener();
    };
  }, [addListener, queryClient, transferPlanNumber]);

  return (
    <div>
      <div className={'person-page'}>
        <div className={'person-transfer-state'}>
          {byTransferState &&
            Object.entries(byTransferState)
              .map((entry) => ({
                key: entry[0],
                value: entry[1],
              }))
              .map((entry) => (
                <div key={`${entry.key}`}>
                  <PersonGroup
                    title={translateTransferState(entry.key)}
                    actions={TransferState.ON_TRANSFER === entry.key && <Actions />}
                  >
                    {entry.value.map((person) => (
                      <PersonCard
                        key={'person-card-'.concat(person.tppId)}
                        onPersonCardClick={selectPerson}
                        person={person}
                        showFooter={false}
                        locationOfInterest={selectedTransferPlan.vehicle}
                      />
                    ))}
                  </PersonGroup>
                </div>
              ))}
        </div>
      </div>

      {selectedPerson && (
        <PersonDetail
          data-cy={'person-detail'}
          person={selectedPerson}
          onClose={resetSelectedPerson}
          locationOfInterest={selectedTransferPlan.vehicle}
        />
      )}
    </div>
  );
};

const Persons = memo(PersonsBase);
export default Persons;
