const MissingRafTranslationsEnglish = {
  popup: {
    close: 'Close',
  },
  button: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
};

export default MissingRafTranslationsEnglish;
