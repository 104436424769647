import { PersonTypes } from '../../../model/Person';

const SupportedDefaultImage = {
  [PersonTypes.OFFSHORE_WORKER]: `${process.env.PUBLIC_URL}/img/${PersonTypes.OFFSHORE_WORKER}.svg`,
  [PersonTypes.VEHICLE_CREW]: `${process.env.PUBLIC_URL}/img/${PersonTypes.VEHICLE_CREW}.svg`,
};

const fallbackImage = `${process.env.PUBLIC_URL}/img/${PersonTypes.VISITOR}.svg`;

/**
 * This will return the right image to display as default if no image was provided for an
 * individual person
 * @param {string} personType - A person's type
 * @return {string} path to the image
 */
export const getPersonTypeDefaultImage = (personType) => {
  return SupportedDefaultImage[personType] || fallbackImage;
};
