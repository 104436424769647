import './PersonCardFooterLocation.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  calculateMillisPassedFromNow,
  convertMillisecondsToReadableString,
} from '../../../../utils/TrackingTimeUtils';
import { locationPropTypes } from '../../../../../model/Location';
import { translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';

const { getElementClassName } = BEMClassNameUtils('person-card-footer');

export default function PersonCardFooterLocation(props) {
  const timeAgo = convertMillisecondsToReadableString(
    calculateMillisPassedFromNow(props.location.lastUpdateMillis)
  );

  return (
    <div className={getElementClassName('location', props.locationKind)}>
      <div className={getElementClassName('location-name')}>{props.location.name}</div>
      <div className={getElementClassName('location-time-ago')}>
        {translateFormat('timeAgo', { timeAgo: timeAgo })}
      </div>
    </div>
  );
}

PersonCardFooterLocation.propTypes = {
  /** The type of the location*/
  locationKind: PropTypes.oneOf(['previous', 'current']),
  /** @link locationPropTypes */
  location: PropTypes.shape(locationPropTypes),
};
