import { PersonTypes } from '../../model/Person';

/**
 * Groups people according to their {@link PersonTypes}
 *
 * @param {Array<Person>} people People to group
 */
export const getGroupedPersonsByType = (people = []) => {
  const peopleByType = Object.values(PersonTypes).reduce(
    (byPersonType, currentState) => ({
      ...byPersonType,
      [currentState]: [],
    }),
    {}
  );

  return people.reduce((byPersonType, person) => {
    const personType = person.type;
    (byPersonType[personType] || byPersonType[PersonTypes.OTHER]).push(person);

    return byPersonType;
  }, peopleByType);
};
