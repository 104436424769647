import { useMemo } from 'react';
import { useGetRequest } from '../useAxiosRequests';
import { useSelectedTransferPlanContext } from '../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { UseQueryResult } from 'react-query';

// ------------------------------------------------
// Hooks
// ------------------------------------------------

/**
 * @typedef PersonNearVehicle
 * Represents a person that is seen near the vehicle.
 *
 * @property {String} name
 * @property {number} tppId
 * @property {number} personId
 */
/**
 * A hook that fetches a list of people near the vehicle.
 * @return {UseQueryResult}
 */
export const usePeopleNearVehicle = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();

  const vehicleId = selectedTransferPlan?.vehicle?.id;
  const url = useMemo(() => {
    if (!vehicleId) {
      return null;
    }
    return `/omp-service-tracking/whereabouts/nearVehicle/${vehicleId}`;
  }, [vehicleId]);

  return useGetRequest({
    queryKey: ['nearVehicle', vehicleId],
    url,
    options: {
      enabled: !!vehicleId && !!url,
      select: ({ data }) => data,
    },
  });
};
