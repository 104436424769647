/**
 * Some hacky way to execute this given function after a render is done.
 * @param {function} runnable
 */
export const executeAfterRender = (runnable) => {
  // FIXME https://jira.ebo-enterprises.com/browse/OMPTRACK-96
  //  We don't know or don't get an event when we are authenticated.
  //  The "onOpen" should actually be "onAuthenticated".
  //  A setTimeout would ensure us that the "subscribe" is done after the render.
  setTimeout(() => {
    runnable();
  }, 0);
};
