import React, { createContext, useContext } from 'react';

import { useTransferPlanPeopleWithLocation } from '../../../../contexts/TransferPlanPeopleContext/TransferPlanPeopleContext';

// ----------------------------------------------
// Variables / functions
// ----------------------------------------------

/**
 * The "disembark all" functionality can be used in different places. Depending on the situation in the
 * popup the message can differ. This context should give users the flexibility to provide their own message.
 *
 * @type {React.Context<String>}
 */
export const DisembarkAllMessageContext = createContext(undefined);
DisembarkAllMessageContext.displayName = 'DisembarkAllMessageContext';

/**
 * The default implementation for the "disembark all" message.
 *
 * @return {string|null}
 */
const useDefaultDisembarkAllMessage = () => {
  const {
    withLocation,
    allOnVehicle,
    allOnALocation,
    peopleScattered,
  } = useTransferPlanPeopleWithLocation();

  switch (true) {
    case allOnVehicle:
      return 'People are still boarded on the vehicle. Do you want to disembark all (boarded) people?';
    case allOnALocation:
      return 'People are still present on certain location(s). Do you want to continue?';
    case peopleScattered:
      return 'People are still boarded on the vehicle and on certain location(s). Do you want to disembark all (boarded) people?';
    default:
      console.warn('Case not supported', withLocation);
      return null;
  }
};

// ----------------------------------------------
// Providers
// ----------------------------------------------

/**
 * A provider that contains the default implementation of the "disembark all" message.
 */
export const DefaultDisembarkAllMessageProvider = ({ children }) => {
  const message = useDefaultDisembarkAllMessage();

  return (
    <DisembarkAllMessageContext.Provider value={message}>
      {children}
    </DisembarkAllMessageContext.Provider>
  );
};

// ----------------------------------------------
// Hooks
// ----------------------------------------------

export const useDisembarkAllMessage = () => useContext(DisembarkAllMessageContext);
