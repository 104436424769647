/**
 * Searches through the list of locations to get the "most recent" location.
 * This according to the {@link Location#lastUpdateMillis} property.
 *
 * @param {Array<Location>} locations
 * @return {Location}
 */
export const getMostRecentLocation = (locations = []) =>
  locations.reduce((mostRecent, currentLocation) => {
    if (!mostRecent || currentLocation.lastUpdateMillis > mostRecent.lastUpdateMillis) {
      return currentLocation;
    } else {
      return mostRecent;
    }
  });

/**
 * Groups the people by their location.
 * The data only contains the ID of the person and location and not the object itself.
 *
 * @param {Array<PersonWhereabouts>} peopleWhereabouts An array of whereabouts
 * @param {Array<Person>} people An array of persons
 * @return {Map<Location, Array<Person>>} People grouped by location (both ID)
 */
export const getGroupedPersonsByLocation = (peopleWhereabouts = [], people = []) => {
  // These caches are used to fill the map "peopleByLocation". The key of that map
  // may be an object, the location. Even though these objects have equal properties,
  // they don't use the same reference.
  const locationById = new Map();
  const personById = people.reduce((personById, person) => {
    personById.set(person.personId, person);
    return personById;
  }, new Map());

  const peopleByLocation = new Map();

  for (const currentWhereabouts of peopleWhereabouts) {
    const locations = currentWhereabouts.locations;

    if (!(locations && locations.length > 0)) {
      continue;
    }

    const mostRecentLocation = getMostRecentLocation(locations);
    if (!locationById.has(mostRecentLocation.locationId)) {
      locationById.set(mostRecentLocation.locationId, mostRecentLocation);
    }

    const cachedLocation = locationById.get(mostRecentLocation.locationId);

    if (!peopleByLocation.has(cachedLocation)) {
      peopleByLocation.set(mostRecentLocation, []);
    }

    const cachedPerson = personById.get(currentWhereabouts.personId);

    peopleByLocation.get(cachedLocation).push(cachedPerson);
  }

  return peopleByLocation;
};
