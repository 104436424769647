import React, { createContext, useCallback, useContext, useEffect } from 'react';

import { useHashParams } from '../../utils/hooks/useHashParams/useHashParams';
import {
  useReloadTransferPlanState,
  useTransferPlanState,
} from '../../data/api/hooks/useTransferPlanState/useTransferPlanState';

// -------------------------------------------------
// Variables
// -------------------------------------------------

/**
 * @typedef SelectedTransferPlanContextResult
 * An object that holds the currently selected transfer plan and the handler to
 * change it.
 *
 * @property {String} transferPlanNumber
 * @property {TransferPlan} selectedTransferPlan
 * @property {function(TransferPlan): void} setSelectedTransferPlan
 * @property {function(): Promise<void>} reload
 */
/**
 * @type {React.Context<SelectedTransferPlanContextResult>}
 */
const SelectedTransferPlanContext = createContext(undefined);
SelectedTransferPlanContext.displayName = 'SelectedTransferPlanContext';

/**
 * The key used to store the transfer plan data in the URL as a hash parameter.
 * @type {string}
 */
const TRANSFER_PLAN_URL_HASH_PARAM_KEY = 'transferPlan';

// -------------------------------------------------
// Provider
// -------------------------------------------------

/**
 * Provides the currently selected transfer plan to it's consumers.
 * When no transfer plans are available <code>null</code> is returned.
 */
const SelectedTransferPlanContextProvider = ({ children }) => {
  const { get, push, remove } = useHashParams();

  const transferPlanNumber = get(TRANSFER_PLAN_URL_HASH_PARAM_KEY);
  const { isSuccess, data: selectedTransferPlan } = useTransferPlanState(transferPlanNumber);

  /**
   * <p>Whenever a transfer plan number is not received by the API, mostly because
   * it's out of the context, remove the hash parameter.
   *
   * <p>This should not happen in production. A possible scenario is when the link is
   * shared, or the parameter is manually changed.
   */
  useEffect(() => {
    if (isSuccess && !selectedTransferPlan) {
      remove(TRANSFER_PLAN_URL_HASH_PARAM_KEY);
    }
  }, [isSuccess, remove, selectedTransferPlan]);

  const updateUrlHashParam = useCallback(
    (transferPlan) => {
      if (!transferPlan?.name) {
        remove(TRANSFER_PLAN_URL_HASH_PARAM_KEY);
      } else {
        push(TRANSFER_PLAN_URL_HASH_PARAM_KEY, transferPlan.name);
      }
    },
    [push, remove]
  );

  const { reload } = useReloadTransferPlanState(transferPlanNumber);

  return (
    <SelectedTransferPlanContext.Provider
      value={{
        transferPlanNumber,
        selectedTransferPlan,
        setSelectedTransferPlan: updateUrlHashParam,
        reload,
      }}
    >
      {children}
    </SelectedTransferPlanContext.Provider>
  );
};

// -------------------------------------------------
// Hook
// -------------------------------------------------

/**
 * A hook that returns the object holding the currently selected transfer plan, if any.
 *
 * @return {SelectedTransferPlanContextResult}
 */
const useSelectedTransferPlanContext = () => useContext(SelectedTransferPlanContext);

export {
  SelectedTransferPlanContext,
  SelectedTransferPlanContextProvider,
  useSelectedTransferPlanContext,
};
