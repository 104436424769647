import deleteSvg from './delete.svg';
import dropdown from './dropdown.svg';
import downarrow from './downarrow.svg';
import gateway from './gateway.svg';
import persons from './persons.svg';
import teams from './teams.svg';
import worker from './worker.svg';
import join from './join.svg';
import leave from './leave.svg';
import dropmarker from './dropmarker.svg';
import ship from './ship.svg';

/**
 * A helper object that bundles the import statements.
 * It's a good practice to make sure that the icon's have a rectangular shape.
 */
export default {
  delete: deleteSvg,
  dropdown: dropdown,
  gateway: gateway,
  persons: persons,
  teams: teams,
  worker: worker,
  join: join,
  leave: leave,
  downarrow,
  dropmarker,
  ship,
};
