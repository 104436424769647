import { useCallback, useMemo } from 'react';

import { translate, translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import {
  useDisembark,
  useEventSubmission,
} from '../../../../../../data/api/hooks/useEventSubmission/useEventSubmission';
import { useSelectedTransferPlanContext } from '../../../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useSelectedPerson } from '../../../../../../contexts/SelectedPersonContext/SelectedPersonContext';
import { useTransferPlanState } from '../../../../../../data/api/hooks/useTransferPlanState/useTransferPlanState';
import { useOptimisticLocationUpdateHandler } from '../useOptimisticLocationUpdateHandler/useOptimisticLocationUpdateHandler';

/**
 * An enumeration of all the possible actions that can be done.
 */
export const Actions = {
  BOARD: 'board',
  // TODO I think this is only possible when the person is boarding for the very first time
  CONFIRM_BOARDING: 'confirm_boarding',
  DISEMBARK: 'disembark',
  DISEMBARK_TO_LOCATION: 'disembark_to_location',
};

/**
 * @typedef ActionForPersonResult
 * An object that represents the action that can be taken. This is based on the person's location and the
 * location of interest.
 *
 * @property {String|undefined} label The translation for the action related to the location.
 * @property {function(Location): void} submitRequest Submits the actcion to for the given location.
 */
/**
 * A hook that returns the action that can be taken for a location
 *
 * @property {Location} locationOfInterest
 * @return ActionForPersonResult
 */
export const useActionForPerson = (locationOfInterest) => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { selectedPerson, resetSelectedPerson } = useSelectedPerson();
  const { refetch } = useTransferPlanState(selectedTransferPlan?.name);
  const handleOptimisticUpdate = useOptimisticLocationUpdateHandler();

  const { submit: submitDisembark } = useDisembark({
    onSettled: refetch,
    onMutate: handleOptimisticUpdate,
  });
  const { submit } = useEventSubmission({
    onSettled: refetch,
    onMutate: handleOptimisticUpdate,
  });

  const personId = selectedPerson?.personId;
  const transferPlanId = selectedTransferPlan?.id;
  const onTransferPlanVessel = selectedPerson?.currentlyOnTransferPlanVessel;
  const currentLocationId = selectedPerson?.currentLocation?.locationId;
  const locationId = locationOfInterest?.locationId;
  const locationName = locationOfInterest?.name;

  const action = useMemo(() => getAction(onTransferPlanVessel, currentLocationId, locationId), [
    currentLocationId,
    locationId,
    onTransferPlanVessel,
  ]);

  const label = useMemo(() => {
    const labelKey = `person.action.${action}`;

    switch (action) {
      case Actions.BOARD:
      case Actions.DISEMBARK:
        return translate(labelKey);
      case Actions.DISEMBARK_TO_LOCATION:
        return translateFormat(labelKey, {
          location: locationName,
        });
      default:
        return undefined;
    }
  }, [action, locationName]);

  const submitRequest = useCallback(() => {
    const request = {
      toLocation: locationOfInterest,
      personId: personId,
      transferPlanId: transferPlanId,
      timestamp: new Date().getTime(),
    };

    if (action === Actions.DISEMBARK) {
      submitDisembark([request]);
    } else {
      submit([request]);
    }

    resetSelectedPerson();
  }, [
    personId,
    transferPlanId,
    action,
    resetSelectedPerson,
    submitDisembark,
    locationOfInterest,
    submit,
  ]);

  return {
    label,
    submitRequest,
  };
};

/**
 * Returns the action based on the current location and the location the person
 * should go to.
 *
 * <ul>
 *   <li>The go to location is a vehicle and the person currently is on the vehicle: {@link Actions#DISEMBARK}
 *   <li>The go to location is a vehicle and the person is somewhere else: {@link Actions#BOARD}
 *   <li>The go to location is not a vehicle and the person currently is on a vehicle: {@link Actions#DISEMBARK_TO_LOCATION}
 *   <li>The go to location is not a vehicle and the person somewhere else: {@link Actions#BOARD}
 * </ul>
 */
const getAction = (onVessel, currentLocationId, goToLocationId) => {
  const vehicleIdRegex = /(\d*)-VEHICLE/;
  const locationIsVehicle = vehicleIdRegex.test(goToLocationId);

  if (locationIsVehicle) {
    if (currentLocationId === goToLocationId) {
      return Actions.DISEMBARK;
    } else {
      return Actions.BOARD;
    }
  } else {
    if (onVessel) {
      return Actions.DISEMBARK_TO_LOCATION;
    } else {
      return Actions.BOARD;
    }
  }
};
