import { noop } from 'raf-core-react/dist/utils/functions/noop';

import { TransferState } from '../../getGroupedPersonsByTransferState/getGroupedPersonsByTransferState';
import { useDisembark } from '../../../data/api/hooks/useEventSubmission/useEventSubmission';
import { useTransferPlanPeopleContext } from '../../../contexts/TransferPlanPeopleContext/TransferPlanPeopleContext';
import { useSelectedTransferPlanContext } from '../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useCallback, useMemo } from 'react';

// --------------------------------------------
// Hooks
// --------------------------------------------

/**
 * @typedef DisembarkAllMutation
 * A mutation object that allows users to disembark all the people on the transfer. If there's no one on the transfer nothing will happen.
 *
 * @property {boolean} isLoading Is the mutation busy or not.
 * @property {boolean} canExecute Can the mutation be done? (Also integrated into the function itself)
 * @property {function(): void} execute The function that disembarks all the people
 */
/**
 * A custom hook which disembarks all the people on the transfer.
 *
 * @param {function} onSettled This function should be executed after the disembarking.
 *
 * @return DisembarkAllMutation
 */
export const useDisembarkAllOnTransfer = ({ onSettled = noop } = {}) => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { byTransferState } = useTransferPlanPeopleContext();
  const { submit, isLoading } = useDisembark({
    onSettled,
  });

  const transferPlanId = selectedTransferPlan?.id;

  const canExecute = useMemo(
    () =>
      byTransferState &&
      byTransferState[TransferState.ON_TRANSFER] &&
      byTransferState[TransferState.ON_TRANSFER].length > 0,
    [byTransferState]
  );

  const execute = useCallback(() => {
    if (!canExecute) {
      return;
    }

    const requests = byTransferState[TransferState.ON_TRANSFER].map((person) => ({
      personId: person.personId,
      transferPlanId: transferPlanId,
      timestamp: new Date().getTime(),
    }));

    submit(requests);
  }, [byTransferState, canExecute, submit, transferPlanId]);

  return {
    canExecute,
    isLoading,
    execute,
  };
};
