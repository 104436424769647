import ExtendedEventTarget from 'raf-core-react/dist/utils/events/ExtendedEventTarget/ExtendedEventTarget';

const events = ['close', 'error', 'message', 'open'];

// Copied from raf-core-react and removed the dependency to "store.js"
// See OMPTRACK-96
/**
 * The websocket proxy is aptly names as it proxies methods/properties for a websocket.
 * Combined with the reference-counting store, this allows us to work with the proxy as
 * any other websocket and thus get the same behavior for methods and open/close events,
 * while multiplexing the access to the socket from different parts of the code.
 *
 * If in the future we would implement a publish/subscribe mechanism over the websocket
 * This could also be used to multiplex multiple subscriptions
 * to the same topic from different parts of the application.
 *
 * In case you wonder if this can be done with ES proxies:
 * I first tried implementing this with ES Proxies
 * but the code quickly become more complex than necessary
 *
 * For api, see https://www.w3.org/TR/websockets/
 */
class WebSocketProxy extends ExtendedEventTarget {
  /**
   *
   * @param {string} url The URL to which to connect; this should be the URL to which the WebSocket server will respond.
   * @param {string|string[]} protocols
   * Either a single protocol string or an array of protocol strings.
   * These strings are used to indicate sub-protocols,
   * so that a single server can implement multiple WebSocket sub-protocols
   * (for example, you might want one server to be able to handle different types of interactions
   * depending on the specified protocol).
   */
  constructor(url, protocols) {
    super();
    this._websocket = new WebSocket(url, protocols);
    this._url = url;
    this._protocols = protocols;
    this._readyState = WebSocket.CONNECTING;
    // An event can not be re-dispatched, so we clone it.
    this._eventHandler = (event) => this.dispatchEvent(new event.constructor(event.type, event));
    this._sendToken = this._sendToken.bind(this);

    events.forEach((eventName) => {
      this._websocket.addEventListener(eventName, this._eventHandler);

      this.addEventListener(eventName, (event) => {
        const handler = `on${eventName}`;
        if (typeof this[handler] === 'function') {
          this[handler](event);
        }
      });
    });

    if (this._websocket.readyState === WebSocket.OPEN) {
      // When the websocket was already open, keep the expected behavior of regular sockets
      // By manually raising the open event
      // We add this to the end of the event loop so the synchronous code path
      // can still add the handlers
      setTimeout(() => {
        // Make sure we didn't receive a closing request in the meantime
        if (
          this._readyState === WebSocket.CONNECTING &&
          this._websocket.readyState === WebSocket.OPEN
        ) {
          delete this._readyState;
          this.dispatchEvent(new Event('open'));
        }
      });
    }
  }

  get binaryType() {
    return this._websocket.binaryType;
  }

  get buffered() {
    return this._websocket.buffered;
  }

  get extensions() {
    return this._websocket.extensions;
  }

  get protocol() {
    return this._websocket.protocol;
  }

  get readyState() {
    return this.hasOwnProperty('_readyState') ? this._readyState : this._websocket.readyState;
  }

  get url() {
    return this._websocket.url;
  }

  authenticate(token) {
    if (this._websocket.token === token) return;
    this._websocket.token = token;
    if (this._websocket.readyState === WebSocket.OPEN) {
      this._sendToken();
    } else {
      // We allow the user to set the authentication token during the connecting phase
      // There is an (unlikely) edge case that the token changes exactly during the connecting phase
      // So we make sure we only send the token once in that case by first cleaning up the pending listener
      this.removeEventListener('open', this._sendToken);
      this.once('open', this._sendToken);
    }
  }

  _sendToken() {
    this.send(`Bearer ${this._websocket.token}`);
  }

  close(code, reason) {
    // Websocket still has remaining holders, so was not closed.
    // So instead, we act as if it was for the holder of this proxy instance.
    events.forEach((eventType) => {
      this._websocket.removeEventListener(eventType, this._eventHandler);
    });
    this._readyState = WebSocket.CLOSING;
    setTimeout(() => {
      this._readyState = WebSocket.CLOSED;
      this.dispatchEvent(new CloseEvent('close', { code, reason }));
    });
  }

  send(data) {
    return this._websocket.send(data);
  }
}

export default WebSocketProxy;
