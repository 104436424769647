import './PersonCardBody.scss';

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { personPropTypes } from '../../../../model/Person';
import { getPersonBoardingState } from '../../utils/PersonUtils';
import { translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { PersonIconBar } from '../PersonIconBar/PersonIconBar';
import { locationPropTypes } from '../../../../model/Location';
import { usePersonPlannedForLocation } from '../../../../utils/hooks/usePersonPlannedForLocation/usePersonPlannedForLocation';

// ----------------------------------------------
// Variables
// ----------------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-card-body');

// ----------------------------------------------
// Component
// ----------------------------------------------

export const PersonCardBodyBase = ({ person, locationOfInterest }) => {
  const isPlanned = usePersonPlannedForLocation({
    person,
    location: locationOfInterest,
  });

  const personBoardingState = getPersonBoardingState(locationOfInterest, person, isPlanned);

  return (
    <div className={getBlockClassName(personBoardingState)}>
      <div className={getElementClassName('name')}>{person.name}</div>
      <div className={getElementClassName('nok')}>
        {person.nok && translateFormat('person.nok', { number: person.nok })}
      </div>

      <PersonIconBar person={person} />
    </div>
  );
};

PersonCardBodyBase.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  locationOfInterest: PropTypes.shape(locationPropTypes),
};

const PersonCardBody = memo(PersonCardBodyBase);
export default PersonCardBody;
