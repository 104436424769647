import { useTransferPlanActivities } from '../../../data/api/hooks/useTransferPlanActivities/useTransferPlanActivities';

/**
 * Checks if the person is planned for the given location. Which is based on the activities.
 * The person's flag "unplanned" is used whenever he is not listed in the activities or he's on the transfer
 * vessel.
 *
 * @param {Person} person The person to check
 * @param {Location} location The location of the person
 * @return {boolean} Flag indicating the person is planned or not
 */
export const usePersonPlannedForLocation = ({ person, location }) => {
  const { data: activities = [] } = useTransferPlanActivities();

  const isPlannedAccordingToTransferPlan = !person.unplanned;

  if (person.currentlyOnTransferPlanVessel) {
    return isPlannedAccordingToTransferPlan;
  }

  const personFromActivities = activities
    .filter((activity) => activity.location.locationId === location.locationId)
    .flatMap((activity) => activity.plannedPeople || [])
    .find((plannedPerson) => plannedPerson.personId === person.personId);

  return !!personFromActivities || isPlannedAccordingToTransferPlan;
};
