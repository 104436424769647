import './PersonCardHeader.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { trackingStatusIndicatorMaxMinutes as maxMinutes } from '../../../../data/api/config';

import TrackingStatusIndicator from '../../../trackingStatusIndicator/TrackingStatusIndicator';

import { getPersonBoundednessClass } from '../../../utils/PersonTypeModifiers';
import {
  calculateMinutesPassedFromNow,
  getTrackingBackgroundColorClass,
} from '../../../utils/TrackingTimeUtils';

import { personPropTypes } from '../../../../model/Person';
import { locationPropTypes } from '../../../../model/Location';

export default function PersonCardHeader(props) {
  const [minutesPassed, setMinutesPassed] = useState(
    calculateMinutesPassedFromNow(props.locationTrackingEventTimestampMs)
  );
  const personBoundednessClass = props.showPersonBoundedness
    ? getPersonBoundednessClass(props.internal)
    : '';

  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesPassed(calculateMinutesPassedFromNow(props.locationTrackingEventTimestampMs));
    }, 5000);
    return () => clearInterval(interval);
  }, [props.locationTrackingEventTimestampMs]);

  return (
    <div
      className={'person-card-header '.concat(
        `${getTrackingBackgroundColorClass(
          props.locationTrackingEventTimestampMs,
          maxMinutes
        )} ${personBoundednessClass}`
      )}
    >
      {props.isTracked &&
        minutesPassed < maxMinutes &&
        props.locationTrackingEventTimestampMs !== undefined && (
          <TrackingStatusIndicator
            locationTrackingEventTimestampMs={props.locationTrackingEventTimestampMs}
            iconDiameter={30}
            iconStroke={6}
            dashArray={57}
          />
        )}
    </div>
  );
}

PersonCardHeader.propTypes = {
  /** {@link personPropTypes.internal} */
  internal: personPropTypes.internal,
  /** {@link locationPropTypes.locationTrackingEventTimestampMs} */
  locationTrackingEventTimestampMs: locationPropTypes.lastUpdateMillis,
  /**
   * A person is tracked when he's seen on a location using
   * "automatic events". So no manual boarding/disembarking/...
   */
  isTracked: PropTypes.bool,
  /** Boolean that indicates whether the person boundedness should be shown or not.
   * It is visualised by a folded corner on top of the card with the representing color.*/
  showPersonBoundedness: PropTypes.bool,
};

PersonCardHeader.defaultProps = {
  locationTrackingEventTimestampMs: 0,
  showPersonBoundedness: false,
};
