import React, { memo, useCallback } from 'react';

import OutputValue from 'ebo-react-component-library/dist/javascript/components/output/OutputValue/OutputValue';

import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { useTransferPlanStateContext } from '../../../../contexts/TransferPlanListOfTodayContext/TransferPlanListOfTodayContext';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import './TransferPlanList.scss';
import { useVehicleImageUri } from '../../../../data/api/hooks/useEntityImageUri/useVehicleImageUri';

// --------------------------------------------
// Variables
// --------------------------------------------

const { getBlockClassName: bList, getElementClassName: eList } = BEMClassNameUtils(
  'transfer-plan-list'
);
const { getBlockClassName: bItem, getElementClassName: eItem } = BEMClassNameUtils(eList('item'));

const emptyLabel = translate('transferPlan.selection.emptyList');
const vesselNameLabel = translate('transferPlan.vessel.name');
const vesselPictureLabel = translate('transferPlan.vessel.picture');
const startLabel = translate('transferPlan.start');
const endLabel = translate('transferPlan.end');
const workLocationsLabel = translate('transferPlan.workLocations');

// --------------------------------------------
// Component
// --------------------------------------------

/**
 * The list item that's represented by a single transfer plan.
 *
 * @param {object} props
 * @param {TransferPlan} props.transferPlan Display the information of this transfer plan
 * @param {boolean} props.isSelected Flag indicating if the given transfer plan is selected
 * @param {function(TransferPlan): void} props.setSelectedTransferPlan Function to select the transfer plan
 */
const TransferPlanItemBase = ({ transferPlan, isSelected, setSelectedTransferPlan }) => {
  const vehicleImageUri = useVehicleImageUri(transferPlan.vehicle.id);

  return (
    <li
      className={bItem(isSelected && 'selected')}
      onClick={() => setSelectedTransferPlan(transferPlan)}
    >
      <div className={eItem('header')}>
        <OutputValue classModifier={'name'} value={transferPlan.name} />
      </div>
      <div className={eItem('content')}>
        <div className={eItem('vessel-picture')}>
          <img className={eItem('vessel-picture')} alt={vesselPictureLabel} src={vehicleImageUri} />
        </div>
        <div className={eItem('transfer-plan-details')}>
          <OutputValue
            label={vesselNameLabel}
            classModifier={'vessel-name'}
            value={transferPlan.vehicle.name}
          />
          <OutputValue label={startLabel} classModifier={'etd'} value={transferPlan.etd} />
          <OutputValue label={endLabel} classModifier={'eta'} value={transferPlan.eta} />
          <OutputValue
            label={workLocationsLabel}
            classModifier={'concessions'}
            value={transferPlan.concessions}
          />
        </div>
      </div>
    </li>
  );
};

/**
 * @see TransferPlanItemBase
 */
const TransferPlanItem = memo(TransferPlanItemBase);

/**
 * Represents a list of transfer plans where the user can chose from.
 *
 * @param {object} props
 * @param {TransferPlan} props.selectedTransferPlan The transfer plan that's selected from the list
 * @param {function(TransferPlan): void} props.setSelectedTransferPlan Function to select the transfer plan
 */
const TransferPlanListBase = ({ selectedTransferPlan, setSelectedTransferPlan }) => {
  const { transferPlans } = useTransferPlanStateContext();

  const isSelected = useCallback(
    (transferPlan) =>
      transferPlan && selectedTransferPlan && transferPlan.id === selectedTransferPlan.id,
    [selectedTransferPlan]
  );

  if (!(transferPlans && transferPlans.length > 0)) {
    return <div className={bList('empty')}>{emptyLabel}</div>;
  }

  return (
    <ul className={bList()}>
      {transferPlans.map((transferPlan) => (
        <TransferPlanItem
          key={`transfer-plan-${transferPlan.id}`}
          transferPlan={transferPlan}
          setSelectedTransferPlan={setSelectedTransferPlan}
          isSelected={isSelected(transferPlan)}
        />
      ))}
    </ul>
  );
};

export const TransferPlanList = memo(TransferPlanListBase);
