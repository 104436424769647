import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useWebSocketContext } from './WebSocketContext';

// -------------------------------------------
// Variables
// -------------------------------------------

/**
 * Create an empty object that should hold the listeners.
 *
 * @return {{message: *[], error: *[], close: *[], open: *[]}}
 */
const createInitialListeners = () => ({
  open: [],
  close: [],
  message: [],
  error: [],
});

/**
 * Same list as in {@link WebSocketProxy}
 * @type {string[]}
 *
 * @see raf-core-react/dist/utils/websockets/WebSocketProxy/WebSocketProxy.js
 */
export const Events = Object.keys(createInitialListeners());

/**
 * @typedef TopicRequest
 * An update request gotten from the API.
 *
 * @property {String} method
 * @property {object} params
 */
/**
 * Transforms the web socket event data into a "topic request".
 *
 * @param {String} data The data part of the web socket message.
 * @return {TopicRequest}
 */
const eventToRequest = ({ data }) => {
  if (data.startsWith('request:')) {
    const message = data.substring('request:'.length);
    return JSON.parse(message);
  }
};

/**
 * @typedef {function(Event): void} WebSocketListener
 * Processes the incoming event.
 *
 * @param {Event} webSocketEvent The event dispatched by the web socket.
 */
/**
 * @typedef {function(): void} RemoveWebSocketListener
 * This function removes the listener that was added.
 */
/**
 * @typedef {function(String, WebSocketListener): RemoveWebSocketListener} AddWebSocketListener
 * Adds the listener for the given event type and returns a function that can remove the listener.
 *
 * @param {String} eventName The event to listen to.
 * @param {WebSocketListener} webSocketListener The function that should be executed.
 * @return {RemoveWebSocketListener} The function to remove the listener.
 */
/**
 * @typedef WebSocketListenerContextValue
 *
 * @property {AddWebSocketListener} addListener Add the listener for the given type. Call the return value if you want to remove the listener.
 */
/**
 * @type {React.Context<WebSocketListenerContextValue>}
 */
const WebSocketListenersContext = createContext(undefined);

// -------------------------------------------
// Provider
// -------------------------------------------

/**
 * Holds all the listeners for the following {@link Events}. The listeners are also added to the web socket to process the messages.
 */
export const WebSocketListenersContextProvider = ({ children }) => {
  const { webSocketProxy, reInitiate } = useWebSocketContext();
  const listeners = useRef(createInitialListeners());

  const removeListener = useCallback((eventName, listenerToRemove) => {
    listeners.current[eventName] = listeners.current[eventName].filter(
      (listener) => listener !== listenerToRemove
    );
  }, []);

  const addListener = useCallback(
    (eventName, listener) => {
      listeners.current[eventName].push(listener);

      return () => removeListener(eventName, listener);
    },
    [removeListener]
  );

  useEffect(() => {
    const executeListener = (eventName, data) => {
      if (!(eventName || data)) {
        return;
      }

      listeners.current[eventName].forEach((handler) => handler(data));
    };

    const handleOpen = (event) => executeListener('open', event);
    const handleClose = (event) => executeListener('close', event);
    const handleMessage = (event) => executeListener('message', eventToRequest(event));
    const handleError = (event) => executeListener('error', event);

    webSocketProxy.on('open', handleOpen);
    webSocketProxy.on('close', handleClose);
    webSocketProxy.on('message', handleMessage);
    webSocketProxy.on('error', handleError);

    return () => {
      webSocketProxy.off('open', handleOpen);
      webSocketProxy.off('close', handleClose);
      webSocketProxy.off('message', handleMessage);
      webSocketProxy.off('error', handleError);
    };
  }, [webSocketProxy]);

  useEffect(() => {
    const removeCloseListener = addListener('close', () => {
      reInitiate();
    });

    return () => removeCloseListener();
  }, [addListener, reInitiate]);

  return (
    <WebSocketListenersContext.Provider
      value={{
        addListener,
      }}
    >
      {children}
    </WebSocketListenersContext.Provider>
  );
};

// -------------------------------------------
// Hooks
// -------------------------------------------

export const useWebSocketListeners = () => useContext(WebSocketListenersContext);
