import PropTypes from 'prop-types';

// --------------------------------------------------
// Variables
// --------------------------------------------------

export const JoinOrLeave = {
  JOIN: 'JOIN',
  LEAVE: 'LEAVE',
};

/**
 * @typedef TransferPlanMovement
 * This object represents the "movement" that a person makes during a transfer plan.
 * The movement is usually based on the planned activity.
 *
 * @property {String} joinOrLeave Is the person joining or leaving the vessel
 * @property {String} locationName The name of the location the person is going/coming from
 * @property {Number} millis The time of the movement
 */
/**
 * Represents {@link TransferPlanMovement}.
 */
export const transferPlanMovementPropType = {
  joinOrLeave: PropTypes.oneOf(Object.values(JoinOrLeave)).isRequired,
  locationName: PropTypes.string.isRequired,
  millis: PropTypes.number.isRequired,
};
