import React, { createContext, memo, useContext } from 'react';
import useTrackingAuthorization from '../../data/api/hooks/useTrackingAuthorization/useTrackingAuthorization';
import LoadingAsOverlay from '../../data/loading/LoadingAsOverlay';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

//////////////////////////////////////////////
// Context
//////////////////////////////////////////////

/**
 * @type {React.Context<TrackingAuthorization>}
 */
const AuthorizationContext = createContext({
  view: false,
  edit: false,
});

//////////////////////////////////////////////
// Variables
//////////////////////////////////////////////

const initializingAuthorizationLabel = translate('authorization.initializing');
const noAuthorizationLabel = translate('authorization.error.noData');

//////////////////////////////////////////////
// Provider
//////////////////////////////////////////////

/**
 * Provides the user with the {@link TrackingAuthorization}.
 * Take in mind that the value should always be a read-only object.
 */
const AuthorizationContextProvider = memo(({ children }) => {
  const { isLoading, isError, data: trackingAuthorization } = useTrackingAuthorization();

  if (isLoading) {
    return <LoadingAsOverlay messageTranslated={initializingAuthorizationLabel} />;
  }

  if (isError || !trackingAuthorization) {
    return (
      <div className={'loading-as-overlay-wrapper'}>
        <span className={'loading-as-overlay-message'}>{noAuthorizationLabel}</span>
      </div>
    );
  }

  return (
    <AuthorizationContext.Provider value={trackingAuthorization}>
      {children}
    </AuthorizationContext.Provider>
  );
});
AuthorizationContextProvider.displayName = 'AuthorizationContextProvider';

//////////////////////////////////////////////
// Hooks
//////////////////////////////////////////////

/**
 * Consumes the {@link AuthorizationContext}.
 *
 * @returns TrackingAuthorization
 */
const useAuthorizationContext = () => useContext(AuthorizationContext);

//////////////////////////////////////////////

export { AuthorizationContextProvider, useAuthorizationContext };
