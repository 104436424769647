import './TrackingStatusIndicator.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { calculateMinutesPassedFromNow } from '../utils/TrackingTimeUtils';
import { trackingStatusIndicatorMaxMinutes as maxMinutes } from '../../data/api/config';

export default function TrackingStatusIndicator(props) {
  const [minutesPassed, setMinutesPassed] = useState(
    determineTimeToUse(
      calculateMinutesPassedFromNow(props.locationTrackingEventTimestampMs),
      maxMinutes
    )
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesPassed(
        determineTimeToUse(
          calculateMinutesPassedFromNow(props.locationTrackingEventTimestampMs),
          maxMinutes
        )
      );
    }, 10000);
    return () => clearInterval(interval);
  });

  return (
    <svg
      className={'tracking-circles'}
      style={{
        width: props.iconDiameter,
        height: props.iconDiameter,
      }}
    >
      <circle
        className={'tracking-circle tracking-circle__outer'}
        style={{
          strokeWidth: props.iconStroke,
        }}
        cx={props.iconDiameter / 2}
        cy={props.iconDiameter / 2}
        r={props.iconDiameter / 2 - props.iconStroke}
      />

      <circle
        className={'tracking-circle tracking-circle__inner'}
        style={{
          strokeWidth: props.iconStroke,
          strokeDasharray: props.dashArray,
          strokeDashoffset: calcDashArrayOffset(props.dashArray, minutesPassed, maxMinutes),
        }}
        cx={props.iconDiameter / 2}
        cy={props.iconDiameter / 2}
        r={props.iconDiameter / 2 - props.iconStroke}
      />
    </svg>
  );
}

const calcDashArrayOffset = (dashArray, minutesPassed, maxMinutes) => {
  return dashArray - (dashArray * minutesPassed) / maxMinutes;
};

export const determineTimeToUse = (minutesPassed, maxMinutes) => {
  let minutesToZeroOrPassed = minutesPassed === undefined ? 0 : minutesPassed;
  return minutesToZeroOrPassed > maxMinutes ? maxMinutes : minutesToZeroOrPassed;
};

TrackingStatusIndicator.propTypes = {
  /** A timestamp from the past that is used to calculate the  minutes that have passed until now */
  locationTrackingEventTimestampMs: PropTypes.number,
  /** The diameter from the icon */
  iconDiameter: PropTypes.number,
  /** The thickness of the icon stroke */
  iconStroke: PropTypes.number,
  /** This parameter indicates a css-property to define the stroke distribution around the icon
   *  and thus indicates the passed time. Every dashArray-property is different for the given
   *  icon diameter and stroke. To determine the correct value you'll need to reproduce the
   *  following steps:
   *    1) Include the component on the page and assign the properties: iconDiameter, iconStroke,
   *    maxMinutes & locationTrackingEventTimestampMs (tip: use 60 minutes as max and a timestamp from 30 minutes ago
   *    just for now
   *    2) Show the component in a browser & open element inspector.
   *    3) The tracking-circle__inner has now 2 inline css parameters which have a value of 0
   *    (stroke-dashArray & stroke-dashOffset)
   *    4) Increase the value of these 2 parameters equally until the icon is fully grayed out.
   *    The obtained value is the dashArray property of this component.
   *    5) Assign this value to the dashArray property and reload the page. The circle should
   *    now be perfectly in half.
   */
  dashArray: PropTypes.number,
};

TrackingStatusIndicator.defaultProps = {
  dashArray: 0,
  locationTrackingEventTimestampMs: 0,
};
