import { getClosestFutureMovement } from '../../components/person/hooks/useLatestTransferPlanMovement/useNextFutureMovement';
import { JoinOrLeave } from '../../model/TransferPlanMovement';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

export const TransferState = {
  ON_TRANSFER: 'ON_TRANSFER',
  NOT_ON_TRANSFER: 'NOT_ON_TRANSFER',
  JOINING: 'JOINING',
  LEFT_TRANSFER: 'LEFT_TRANSFER',
};

export const translateTransferState = (transferState = '') =>
  translate(`transferPlan.state.${transferState.toLowerCase()}`);

/**
 * Calculate the {@link TransferState} according for the given person.
 *
 * @param {Person} person
 * @param {boolean} tracked
 * @return One of the enum values of {@link TransferState}
 * @see getGroupedPersonsByTransferState
 */
const getTransferStateForPerson = (person, tracked) => {
  if (person.currentlyOnTransferPlanVessel) {
    return TransferState.ON_TRANSFER;
  }

  if (person.disembarked) {
    return TransferState.LEFT_TRANSFER;
  }

  const transferPlanMovement = getClosestFutureMovement(person.movements);
  if (transferPlanMovement?.joinOrLeave) {
    if (JoinOrLeave.JOIN === transferPlanMovement.joinOrLeave) {
      return TransferState.JOINING;
    } else {
      return TransferState.LEFT_TRANSFER;
    }
  }

  const isPlanned = !person.unplanned;
  if (tracked || isPlanned) {
    return TransferState.NOT_ON_TRANSFER;
  }

  return TransferState.LEFT_TRANSFER;
};

/**
 * This function marks any person as "not tracked".
 *
 * @param {number} personId The person being marked.
 * @return {false}
 */
const notTracked = ({ personId }) => false;

/**
 * Groups the people according to their transfer state
 *
 * @param {Array<Person>} persons
 * @param {function({ personId: number }): boolean} isPersonTracked
 *
 * @see <a href="https://confluence.ebo-enterprises.com/pages/viewpage.action?pageId=83735182#Transferplanview-Personview">Confluence: Person view</a>
 */
export const getGroupedPersonsByTransferState = (persons = [], isPersonTracked = notTracked) => {
  const peopleByTransferState = Object.values(TransferState).reduce(
    (byTransfer, currentState) => ({
      ...byTransfer,
      [currentState]: [],
    }),
    {}
  );

  return persons.reduce((byTransferState, person) => {
    const tracked = isPersonTracked(person);
    const transferState = getTransferStateForPerson(person, tracked);

    byTransferState[transferState].push(person);

    return byTransferState;
  }, peopleByTransferState);
};
