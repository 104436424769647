import React, { createContext, useContext } from 'react';
import { useGatewayStatus } from '../../data/api/hooks/useGatewayStatus/useGatewayStatus';
import { useSelectedTransferPlanContext } from '../SelectedTransferPlanContext/SelectedTransferPlanContext';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

/**
 * @type {React.Context<GatewayStatus>}
 */
const VehicleGatewayStatusContext = createContext(undefined);

//-----------------------------------------------------
// Components
//-----------------------------------------------------

/**
 * This component provides data concerning the current gateway.
 */
const VehicleGatewayStatusContextProvider = ({ children }) => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { data: gatewayStatus } = useGatewayStatus(selectedTransferPlan?.vehicle);

  return (
    <VehicleGatewayStatusContext.Provider value={gatewayStatus}>
      {children}
    </VehicleGatewayStatusContext.Provider>
  );
};

//-----------------------------------------------------
// Hooks
//-----------------------------------------------------

/**
 * A consumer hook that reads the status of the current gateway.
 *
 * @return GatewayStatus
 */
const useVehicleGatewayStatus = () => useContext(VehicleGatewayStatusContext);

export { VehicleGatewayStatusContextProvider, useVehicleGatewayStatus };
