import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useWebSocketListeners } from '../WebSocketContext/WebSocketListenersContext';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

/**
 * @typedef ApiStatus
 * An object holding status information about API connection.
 *
 * @property {boolean} connectionLost Flag indicating if the connection is lost
 */
/**
 * @type {React.Context<ApiStatus>}
 */
const ApiStatusContext = createContext(undefined);

const SET_ERROR_MESSAGE_DELAY_MS = 2000;

/**
 * A hook that holds the state of the API status.
 * This status has a strong dependency to the web socket.
 *
 * @return {ApiStatus}
 */
export const useApiStatusState = (initialConnectionState = false) => {
  const connectionClosedInterval = useRef(null);
  const [connectionLost, setConnectionLost] = useState(initialConnectionState);
  const { addListener } = useWebSocketListeners();

  useEffect(() => {
    const removeOpenListener = addListener('open', () => {
      connectionClosedInterval.current && clearInterval(connectionClosedInterval.current);
      setConnectionLost(false);
    });

    const removeCloseListener = addListener('close', () => {
      // In case the connection got broken, for instance by a restart. Multiple "close" events
      // are fired. Therefore when an interval was already fired, clear it on the following
      // incoming "close" events.
      connectionClosedInterval.current && clearInterval(connectionClosedInterval.current);

      if (connectionLost) {
        return;
      }

      connectionClosedInterval.current = setInterval(() => {
        setConnectionLost(true);
      }, SET_ERROR_MESSAGE_DELAY_MS);
    });

    return () => {
      removeOpenListener();
      removeCloseListener();
    };
  }, [addListener, connectionLost]);

  return {
    connectionLost,
  };
};

//-----------------------------------------------------
// Components
//-----------------------------------------------------

/**
 * This component provides data concerning the current gateway.
 */
const ApiStatusContextProvider = ({ children }) => {
  const state = useApiStatusState();

  return <ApiStatusContext.Provider value={state}>{children}</ApiStatusContext.Provider>;
};

//-----------------------------------------------------
// Hooks
//-----------------------------------------------------

/**
 * A consumer hook that reads the status of the API connection.
 *
 * @return ApiStatus
 */
const useApiStatus = () => useContext(ApiStatusContext);

export { ApiStatusContextProvider, useApiStatus };
