import { translate } from 'raf-core-react/dist/utils/localization/Translations';

/**
 * This will return the readable name from the different boarding states.
 * @return {string|undefined}
 */
export const getBoardingStateName = (boardingState) => {
  if (boardingState) {
    return translate('boardingState.'.concat(boardingState)).toUpperCase();
  }
};
