import './PersonCardFooter.scss';

import React from 'react';
import IconWrapper from '../../../../components/image/icon/IconWrapper';
import PropTypes from 'prop-types';
import { locationPropTypes } from '../../../../model/Location';
import PersonCardFooterLocation from './PersonCardFooterLocation/PersonCardFooterLocation';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-card-footer');
const unavailableLocation = translate('location.unavailable.locationInfo');
const lastSeenAt = translate('location.label.lastSeenAt');

export default function PersonCardFooter(props) {
  return (
    <div className={getBlockClassName()}>
      <IconWrapper idPrefix={getBlockClassName()} iconName={'map-marker-alt'} />
      <div className={getElementClassName('all-locations')}>
        {showDifferentLocationKinds(props)}
      </div>
    </div>
  );
}

/**
 * No location is provided --> NO LOCATION INFO AVAILABLE
 * Only previous location is provided --> LAST SEEN AT location x
 * Only current location is provided --> location x
 * 2 locations provided --> location x / location y
 */
const showDifferentLocationKinds = (props) => {
  if (props.currentLocation === undefined) {
    if (props.previousLocation === undefined) {
      return <div className={getElementClassName('location-name')}>{unavailableLocation}</div>;
    } else {
      return (
        <>
          <div className={getElementClassName('location', 'last-seen')}>{lastSeenAt}</div>
          <PersonCardFooterLocation locationKind={'previous'} location={props.previousLocation} />
        </>
      );
    }
  } else {
    if (props.previousLocation === undefined) {
      return <PersonCardFooterLocation locationKind={'current'} location={props.currentLocation} />;
    } else {
      return (
        <>
          <PersonCardFooterLocation locationKind={'current'} location={props.currentLocation} />
          <PersonCardFooterLocation locationKind={'previous'} location={props.previousLocation} />
        </>
      );
    }
  }
};

PersonCardFooter.propTypes = {
  /** {@link personPropTypes.currentLocation} */
  currentLocation: PropTypes.shape(locationPropTypes),
  /** {@link personPropTypes.previousLocation} */
  previousLocation: PropTypes.shape(locationPropTypes),
};
