import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQuery } from 'react-query';

import { config } from '../config';
import { createGetMethod, createPostMethod } from 'raf-core-react/dist/utils/axios/axiosUtils';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable no-unused-vars */
import {
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
  QueryFunction,
  UseMutationOptions,
  UseMutationResult,
  MutationFunction,
} from 'react-query';
/* eslint-enable no-unused-vars */

//---------------------------------------------------------
// Hooks
//---------------------------------------------------------

/**
 * @typedef DefaultHttpConfig
 * A small object that holds the needed info to perform requests.
 *
 * @property {string} baseURL The base URL of the end points
 * @property {object} headers The Headers that will be added to the request.
 * @property {string} headers.Authorization The Authorization header.
 */
/**
 * Creates an Axios configuration based on a token.
 *
 * @param {string} token The current authentication token
 * @return {DefaultHttpConfig}
 */
export const createAxiosHttpConfig = (token) => ({
  baseURL: config.rest.url,
  headers: {
    Authorization: `Bearer ${token}`,
    // Don't send this header as your omp-mgmt cookie, if you have one
    // will not get destroyed, thus being logged in on mgmt.
    // 'X-Keep-Session-Alive': 'true',
  },
});

/**
 * @typedef HttpConfigExtension
 * Additional parameters that can be merged with the {@link DefaultHttpConfig}.
 *
 * @property {string} responseType The expected type from the response.
 */
/**
 * @callback QueryFunctionFactory
 * This factory creates a query function using the given http config.
 *
 * @param {DefaultHttpConfig} httpConfig The configuration needed to perform requests.
 * @return {QueryFunction} The function that will be used by 'react-query'
 */
/**
 * A wrapper hook that creates an {@link DefaultHttpConfig} object using the
 * authenticated token along with other parameters. This object is then passed
 * to the factory and the result will be used as the "base" function.
 *
 * @param {QueryKey} queryKey
 * @param {QueryFunctionFactory} queryFnFactory
 * @param {UseQueryOptions?} options
 * @return {UseQueryResult}
 */
const useAxiosQuery = (queryKey, queryFnFactory, options) => {
  // This hook returns a reference of the global variable. This variable is
  // updated but doesn't trigger re-renders (which makes sense, you don't want
  // to render on every new token).
  // For this reason, create the "http config" inside the "useQuery".
  const { keycloak } = useKeycloak();

  return useQuery(
    queryKey,
    () => {
      const httpConfig = createAxiosHttpConfig(keycloak.token);

      return queryFnFactory(httpConfig);
    },
    options
  );
};

/**
 * @callback MutationFunctionFactory
 * This factory creates a mutation function using the given http config.
 *
 * @param {Object} data The data that needs to be uploaded to the API
 * @param {DefaultHttpConfig} httpConfig The configuration needed to perform requests.
 * @return {MutationFunction} The function that will be used by 'react-query'
 */
/**
 * A wrapper hook that creates an {@link DefaultHttpConfig} object using the
 * authenticated token along with other parameters. This object is then passed
 * to the factory and the result will be used as the "base" function.
 *
 * @param {MutationFunctionFactory} mutationFnFactory
 * @param {UseMutationOptions?} options
 * @return {UseMutationResult}
 */
const useAxiosMutation = (mutationFnFactory, options) => {
  // This hook returns a reference of the global variable. This variable is
  // updated but doesn't trigger re-renders (which makes sense, you don't want
  // to render on every new token).
  // For this reason, create the "http config" inside the "useMutation".
  const { keycloak } = useKeycloak();

  return useMutation((data) => {
    const httpConfig = {
      baseURL: config.rest.url,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    };

    return mutationFnFactory(data, httpConfig);
  }, options);
};

/**
 * This hook represents the "GET" request. It automatically adds the authentication
 * to it's request along with other parameters.
 * <p>Additional options can be added to fine-tune the hook.
 *
 * @param {QueryKey} queryKey
 * @param {string} url
 * @param {HttpConfigExtension?} httpConfigExtension
 * @param {object?} data
 * @param {UseQueryOptions?} options
 * @return {UseQueryResult}
 *
 * @see useAxiosQuery
 */
const useGetRequest = ({ queryKey, url, httpConfigExtension, data, options }) =>
  useAxiosQuery(
    queryKey,
    (httpConfig) => createGetMethod(url, { ...httpConfigExtension, ...httpConfig })(data),
    options
  );

/**
 * This hook represents the "POST" request. It automatically adds the authentication
 * to it's request along with other parameters.
 * <p>Additional options can be added to fine-tune the hook.
 *
 * @param {string} url
 * @param {UseMutationOptions?} options
 * @return {UseMutationResult}
 *
 * @see useAxiosQuery
 */
const usePostRequest = ({ url, options }) =>
  useAxiosMutation((data, httpConfig) => createPostMethod(url, httpConfig)(data), options);

export { useGetRequest, usePostRequest };
