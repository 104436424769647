import { PersonTypes } from '../../../../../model/Person';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

const workerAboardName = translate('location.groupName.workerAboard');
const crewAboardName = translate('location.groupName.crewAboard');
const visitorAboardName = translate('location.groupName.visitorAboard');

/**
 * @typedef PeopleInBoardingState
 *
 * @property {Array<Person>} people
 * @property {String} groupName
 */
/**
 * Will return a new object with the ON_TRANSFER
 * ordered in a new way to create the correct categorisation.
 * @param {Array<Object>} transferState The transferState.
 * @return {Object<PersonTypes, PeopleInBoardingState>} The transformed object to make divisions between types of people.
 */
export const groupedByPersonType = (transferState) => {
  return transferState.reduce(
    (groups, person) => {
      if (person.currentlyOnTransferPlanVessel) {
        const group = groups[person.type] || groups[PersonTypes.VISITOR];
        group.people.push(person);
      }

      return groups;
    },
    {
      [PersonTypes.OFFSHORE_WORKER]: { people: [], groupName: workerAboardName },
      [PersonTypes.VEHICLE_CREW]: { people: [], groupName: crewAboardName },
      [PersonTypes.VISITOR]: { people: [], groupName: visitorAboardName },
    }
  );
};
