import { useMemo } from 'react';

import { useTransferPlanActivities } from '../../../data/api/hooks/useTransferPlanActivities/useTransferPlanActivities';
import { usePersonWhereabouts } from '../../../contexts/PersonWhereaboutsContext/PersonWhereaboutsContext';
import { useSelectedTransferPlanContext } from '../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';

// ---------------------------------
// Hook
// ---------------------------------

/**
 * @typedef LocationWithPeople
 * A tuple of a location with people on or planned to be on it.
 *
 * @property {Location} location
 * @property {Array<Person>} people
 */
/**
 * This hook returns locations with people on that location or planned to be on that location.
 * The locations are based on the activities of a transfer plan.
 *
 * @return {Array<LocationWithPeople>}
 */
export const useActivityLocationsWithPeople = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { peopleByLocation, getPeopleForLocationId } = usePersonWhereabouts();
  const { data: activities = [] } = useTransferPlanActivities();

  /**
   * The locations of people of the current transfer plan that are not planned in an activity.
   * These are wrapped in an object so they can be merged with the activities into {@link LocationWithPeople}.
   */
  const unplannedLocations = useMemo(() => {
    const activityLocationIds = activities.map((activity) => activity.location.locationId);

    return Array.from(peopleByLocation.keys())
      .filter((location) => {
        const plannedForActivity = activityLocationIds.includes(location.locationId);
        const isTransferPlanVehicle =
          location.locationId === selectedTransferPlan.vehicle.locationId;

        return !(plannedForActivity || isTransferPlanVehicle);
      })
      .map((location) => ({
        location: { ...location, unplanned: true },
      }));
  }, [activities, peopleByLocation, selectedTransferPlan.vehicle.locationId]);

  return useMemo(
    () =>
      activities
        .map((activity) => ({
          location: activity.location,
          plannedPeople: activity.plannedPeople,
        }))
        .concat(unplannedLocations)
        .map(({ location, plannedPeople = [] }) => {
          const peopleOnLocation = getPeopleForLocationId(location.locationId);
          const registeredPeopleIds = new Set(peopleOnLocation.map((person) => person.personId));
          const people = [...peopleOnLocation];

          for (let person of plannedPeople) {
            if (!registeredPeopleIds.has(person.personId)) {
              people.push(person);
              registeredPeopleIds.add(person.personId);
            }
          }

          return {
            location,
            people,
          };
        }),
    [activities, getPeopleForLocationId, unplannedLocations]
  );
};
