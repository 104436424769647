import humanizeDuration from 'humanize-duration';

/**
 * This returns the right tracking state css class. It describes whether the
 * person is tracked or not
 * @param {string} timeStampMs - The time in milliseconds that the tracking event took place
 * @param {number} maxMinutes - The maximum amount of minutes that may have been passed to indicate whether the person is tracked or not
 * @return {string}
 */
export const getTrackingBackgroundColorClass = (timeStampMs, maxMinutes) => {
  const timePassed = calculateMinutesPassedFromNow(timeStampMs);
  if (timePassed > maxMinutes || timePassed === undefined || maxMinutes - timePassed === 0) {
    return 'person-card-header--not-tracked';
  } else {
    return 'person-card-header--tracked';
  }
};

/**
 * A person can have a single location event object, multiple ones or none. This
 * function returns the right timestamp in milliseconds from the most recent tracking event. (Not most recent timestamp)
 * @return {number}
 */
export const getCurrentOrPreviousTimestampFromPersonObject = (personObject) => {
  if (personObject.currentLocation === undefined && personObject.previousLocation === undefined) {
    return 0;
  } else {
    if (personObject.currentLocation !== undefined) {
      return personObject.currentLocation.ms;
    } else {
      return personObject.previousLocation.ms;
    }
  }
};

/**
 * This transforms a time in milleseconds to a readable string e.g 60000ms --> 1m
 * @param {number|string} milliseconds - The amount of time in milliseconds.
 * @return {string}
 */
export const convertMillisecondsToReadableString = (milliseconds) => {
  return humanizeDuration(milliseconds, {
    delimiter: ' ',
    spacer: '',
    largest: 2,
    units: ['d', 'h', 'm'],
    round: true,
    language: 'shortEn',
    languages: {
      shortEn: {
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
      },
    },
  });
};

/**
 * Uses the calculation function and converts it to minutes
 * @see {calculateMillisPassedFromNow}
 * @param {string|number}pastTimeStampMs
 * @return number
 */

export const calculateMinutesPassedFromNow = (pastTimeStampMs) => {
  return Math.floor(calculateMillisPassedFromNow(pastTimeStampMs) / 1000 / 60);
};

/**
 * This function calculates the amount of milliseconds that have passed since the provided epoch timestamp
 * @param {number|string} pastTimeStampMs - The timestamp from the past between which a time difference must be calculated until now
 * @return {number}
 */
export const calculateMillisPassedFromNow = (pastTimeStampMs) => {
  return Date.now() - pastTimeStampMs;
};
