import './PersonCard.scss';

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { usePersonImageUri } from '../../../data/api/hooks/useEntityImageUri/usePersonImageUri';
import { getCurrentOrPreviousTimestampFromPersonObject } from '../../utils/TrackingTimeUtils';

import PersonCardHeader from './PersonCardHeader/PersonCardHeader';
import PersonCardPictureAndType from './PersonCardPictureAndType/PersonCardPictureAndType';
import PersonCardBody from './PersonCardBody/PersonCardBody';
import PersonCardFooter from './PersonCardFooter/PersonCardFooter';

import { personPropTypes } from '../../../model/Person';
import { locationPropTypes } from '../../../model/Location';
import { usePersonIsTracked } from '../hooks/usePersonIsTracked/usePersonIsTracked';

export const PersonCardBase = ({
  id,
  person,
  locationOfInterest,
  onPersonCardClick,
  showFooter,
}) => {
  const personImageUri = usePersonImageUri(person);
  const isTracked = usePersonIsTracked(person);

  const handlePersonCardClick = useCallback(() => {
    onPersonCardClick(person);
  }, [person, onPersonCardClick]);

  return (
    <div data-cy={id} className={'person-card'} onClick={handlePersonCardClick}>
      <PersonCardHeader
        internal={person.internal}
        showPersonBoundedness={false}
        locationTrackingEventTimestampMs={getCurrentOrPreviousTimestampFromPersonObject(person)}
        isTracked={isTracked}
      />
      <PersonCardPictureAndType personType={person.type} personImageUrl={personImageUri} />
      <PersonCardBody person={person} locationOfInterest={locationOfInterest} />
      {showFooter && (
        <PersonCardFooter
          currentLocation={person.currentLocation || {}}
          previousLocation={person.previousLocation || {}}
        />
      )}
    </div>
  );
};

PersonCardBase.propTypes = {
  id: PropTypes.string,
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  /** Boolean that indicates weather this card should have a footer or not*/
  showFooter: PropTypes.bool,
  /** The function that describes what should happen with the card if a user clicks on it */
  onPersonCardClick: PropTypes.func,
  locationOfInterest: PropTypes.shape(locationPropTypes),
};

PersonCardBase.defaultProps = {
  showFooter: false,
};

const PersonCard = memo(PersonCardBase);
export default PersonCard;
