import './App.scss';

import React, { memo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Keycloak from 'keycloak-js';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { ToastContainer } from 'react-toastify';

import { AppContexts } from '../contexts/AppContexts';
import Header from '../components/header/Header';
import { Routes } from '../components/route/Routes/Routes';

import { config } from '../data/api/config';
import { TransferPlanSelection } from '../components/transferplan/TransferPlanSelection/TransferPlanSelection';
import LoadingAsOverlay from '../data/loading/LoadingAsOverlay';

import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import { ReportableToastMessages } from '../components/ToastMessages/ReportableToastMessages';
import ApplicationWideError from 'raf-core-react/dist/components/app/ApplicationWideError/ApplicationWideError';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

const queryClient = new QueryClient();
const keycloak = new Keycloak({
  realm: 'e-wind',
  url: config.keycloak.url,
  clientId: 'omp-track',
});
const initOptions = {
  onLoad: 'login-required',
};
/** Message used in loading icon to indicate that authentication is being initialized. */
const initializingAuthentication = translate('authentication.initializing');

//-----------------------------------------------------
// Components
//-----------------------------------------------------

/**
 * All routes and content of the application have to be secured. A developer
 * shouldn't be worried about the fact that a user is authenticated or not
 * when adding pages.
 *
 * <p>This component renders the application only when the user is authenticated
 * and takes care of that problem. This should be the only place that does this
 * validation.
 */
const SecuredApp = () => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return <LoadingAsOverlay messageTranslated={initializingAuthentication} />;
  }

  return (
    <ReportableToastMessages>
      <BrowserRouter>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <AppContexts>
            <Header />
            <ApplicationWideError />
            <main className={'App'}>
              <Routes />
              <TransferPlanSelection />
            </main>
          </AppContexts>
        </QueryClientProvider>
      </BrowserRouter>
    </ReportableToastMessages>
  );
};

/**
 * Wraps the {@link SecuredApp} with the needed Keycloak provider
 * and data.
 */
const App = () => (
  <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
    <SecuredApp />
  </ReactKeycloakProvider>
);

export default memo(App);
