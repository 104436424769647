import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useSelectedTransferPlanContext } from '../SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useTransferPlanState } from '../../data/api/hooks/useTransferPlanState/useTransferPlanState';

// ---------------------------------------------
// Variables
// ---------------------------------------------

/**
 * @typedef TransferPlanSelectionPopupResult
 * This object holds the state for the transfer plan selection popup. It indicates
 * if the popup is shown and what transfer plan is selected (but not yet confirmed).
 *
 * @property {boolean} init Is it the initial selection or not
 * @property {boolean} hidden Is the popup visible or not
 * @property {TransferPlan} transferPlanForSelection The (unconfirmed) transfer plan that is selected
 * @property {function(TransferPlan): void} setTransferPlanForSelection Set the (unconfirmed) transfer plan
 * @property {function(): void} confirmSelection Confirm the selection, this updates the {@link SelectedTransferPlanContextResult}
 * @property {function(): void} cancelSelection Cancel the selection.
 * @property {function(): void} showPopup Open the popup
 * @property {function(): void} hidePopup Hide the popup
 */
/**
 * @type {React.Context<TransferPlanSelectionPopupResult>}
 */
const TransferPlanSelectionPopupContext = createContext(undefined);
TransferPlanSelectionPopupContext.displayName = 'TransferPlanSelectionPopupContext';

// ---------------------------------------------
// Provider
// ---------------------------------------------

/**
 * A context provider for the transfer plan selection popup. This provider manages the
 * state of the selection inside the popup, so before it's confirmed (before {@link SelectedTransferPlanContextResult}).
 */
export const TransferPlanSelectionPopupContextProvider = ({ children }) => {
  const state = useTransferPlanSelectionPopupState();

  return (
    <TransferPlanSelectionPopupContext.Provider value={state}>
      {children}
    </TransferPlanSelectionPopupContext.Provider>
  );
};

// ---------------------------------------------
// Hooks
// ---------------------------------------------

/**
 * This hook holds the state of the popup and provides functions to select and confirm
 * a transfer plan.
 */
const useTransferPlanSelectionPopupState = () => {
  const {
    transferPlanNumber,
    selectedTransferPlan,
    setSelectedTransferPlan,
  } = useSelectedTransferPlanContext();
  const [hidden, setHidden] = useState(!!transferPlanNumber);
  const [transferPlanForSelection, setTransferPlanForSelection] = useState(selectedTransferPlan);

  const { refetch } = useTransferPlanState(transferPlanForSelection?.name, false);

  /**
   * <p>Open the selection popup when the number is empty. The user should then see
   * the begin scenario of having to select a transfer plan.
   *
   * <p>This should not happen in production. A possible scenario is when the link is
   * shared, or the parameter is manually changed.
   */
  useEffect(() => {
    if (!transferPlanNumber) {
      setHidden(false);
    }
  }, [transferPlanNumber]);

  const showPopup = useCallback(() => {
    setHidden(false);
  }, []);

  const hidePopup = useCallback(() => {
    setHidden(true);
  }, []);

  const confirmSelection = useCallback(() => {
    hidePopup();
    refetch().then(({ data: transferPlanState }) => {
      setSelectedTransferPlan(transferPlanState);
    });
  }, [hidePopup, refetch, setSelectedTransferPlan]);

  return {
    init: !transferPlanNumber,
    hidden,
    transferPlanForSelection,
    setTransferPlanForSelection,
    confirmSelection,
    cancelSelection: hidePopup,
    showPopup,
    hidePopup,
  };
};

/**
 * Returns the current state of the transfer plan selection popup.
 *
 * @return TransferPlanSelectionPopupResult
 */
export const useTransferPlanSelectionPopup = () => useContext(TransferPlanSelectionPopupContext);
