import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { personPropTypes } from '../../../model/Person';
import { translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import './LocationsCard.scss';
import { PersonIconBar } from '../../person/PersonCard/PersonIconBar/PersonIconBar';

// ----------------------------------------------
// Variables
// ----------------------------------------------
const { getBlockClassName, getElementClassName } = BEMClassNameUtils('locations-card');

const nokContainerClassName = getElementClassName('nok-container');
const detailsClassName = getElementClassName('details');
const nokClassName = getElementClassName('details-nok');
const personClassName = getElementClassName('details-person');

/**
 * Checks which classname to use based on planned & boarded
 * @param {object} person
 * @param {boolean} person.unplanned - if the person is planned on the transfer or not
 * @param {boolean} person.currentlyOnTransferPlanVessel - if the person is on board or not
 * @returns {string} - The new classname based on above values
 */
const resolveClassNameForCard = (person) => {
  const unplannedStatus = person.unplanned ? 'unplanned' : 'planned';
  const boardedStatus = person.currentlyOnTransferPlanVessel ? 'boarded' : 'not-boarded';
  return getBlockClassName(`${unplannedStatus}-${boardedStatus}`);
};

// ----------------------------------------------
// Component
// ----------------------------------------------
/**
 * The component to render the view for each seperate person.
 */
const LocationsCardBase = ({ person }) => {
  return (
    <div data-cy={'locations-card'} className={resolveClassNameForCard(person)}>
      <div className={detailsClassName}>
        <div data-cy={'locations-card__name'} className={personClassName}>
          {person.name}
        </div>
        <div className={nokContainerClassName}>
          <PersonIconBar person={person} />
          {person.nok && (
            <div data-cy="locations-card__nok" className={nokClassName}>
              {translateFormat('person.nok', { number: person.nok })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LocationsCard = memo(LocationsCardBase);
export default LocationsCard;

LocationsCardBase.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes).isRequired,
};
