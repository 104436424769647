import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ApplicationWideErrorContextProvider } from 'raf-core-react/dist/contexts/ApplicationWideErrorContext/ApplicationWideErrorContext';

import { useApiStatus } from '../ApiStatusContext/ApiStatusContext';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import './TrackingApplicationWideErrorContext.scss';

// ---------------------------------------------
// Variables
// ---------------------------------------------

const connectionLostLabel = translate('error.connectionLost');

// ---------------------------------------------
// Context Provider
// ---------------------------------------------

/**
 * React component, feeding the ApplicationWideErrorContextProvider with omp-management specific data
 *
 * @param {object} props See {@link TrackingApplicationWideErrorContextProvider.propTypes}
 */
export const TrackingApplicationWideErrorContextProvider = ({ children }) => {
  const { connectionLost } = useApiStatus();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setErrorMessage(connectionLost ? connectionLostLabel : null);
  }, [connectionLost]);

  return (
    <ApplicationWideErrorContextProvider errorMessage={errorMessage}>
      {children}
    </ApplicationWideErrorContextProvider>
  );
};

TrackingApplicationWideErrorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
