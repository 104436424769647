import { useMemo } from 'react';
import { useGetRequest } from '../useAxiosRequests';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { UseQueryResult } from 'react-query';
import { getOrDefaultStaleTime } from '../../config';

// ------------------------------------------------
// Variables
// ------------------------------------------------

/**
 * Pictures can be cached for a long time. They don't change that often.
 * Cache them for a day.
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 60 * 24);

// ------------------------------------------------
// Hooks
// ------------------------------------------------

/**
 * This hook fetches the ID of the given person's photo.
 * @return {UseQueryResult}
 */
const useEntityPhotoAttachmentId = (entityName, id) => {
  const url = useMemo(() => `/${entityName}/${id}/attachment/byPurpose?purpose=PHOTO`, [
    entityName,
    id,
  ]);

  return useGetRequest({
    queryKey: ['imageId', entityName, id],
    url,
    options: {
      staleTime: CACHE_TIME,
      enabled: !!(entityName && id),
      select: ({ data: attachments = [] }) => attachments.map((attachment) => attachment.id)[0],
    },
  });
};

/**
 * Retrieves the photo of the person with the corresponding ID and converts it into a Base64 encoded String.
 * If no photo is available, a default image will be used instead.
 *
 * @param {string} entityName
 * @param {number} entityId
 * @param {string} defaultImage
 *
 * @return {string}
 */
const useEntityImageUri = ({ entityName, entityId, defaultImage }) => {
  const { isSuccess: fetchedPhotoId, data: photoId } = useEntityPhotoAttachmentId(
    entityName,
    entityId
  );
  const enabled = fetchedPhotoId && !!photoId;

  const { isSuccess: fetchedPhoto, data: photoString } = useGetRequest({
    queryKey: ['photo', entityName, entityId],
    url: `/${entityName}/${entityId}/attachment/${photoId}/content`,
    httpConfigExtension: {
      responseType: 'arraybuffer',
    },
    options: {
      staleTime: CACHE_TIME,
      enabled,
      select: ({ headers: { 'content-type': contentType }, data }) => {
        if (data?.byteLength > 0) {
          return `data:${contentType};base64,${Buffer.from(data).toString('base64')}`;
        }
      },
    },
  });

  // Wait until the photo is actually loaded and encoded.
  // This reduces the amount of renders for the users of this hook.
  // Instead of: default -> no image (loading) -> photo, you get: default -> photo
  return useMemo(() => {
    if (enabled && fetchedPhoto && photoString) {
      return photoString;
    } else {
      return defaultImage;
    }
  }, [defaultImage, enabled, fetchedPhoto, photoString]);
};

export { useEntityImageUri };
