import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { noop } from 'raf-core-react/dist/utils/functions/noop';
import { useUniqueId } from 'raf-core-react/dist/hooks/useUniqueId/useUniqueId';
import { useWebSocketContext } from '../WebSocketContext/WebSocketContext';
import { useWebSocketListeners } from '../WebSocketContext/WebSocketListenersContext';
import { executeAfterRender } from './utils/executeAfterRender';

// ----------------------------------------------
// Variables / functions
// ----------------------------------------------

/**
 * @typedef Topic
 * A subscription where a user can retrieve data from. It resembles a certain "type" of data.
 * For instance data concerning "locations", or "transfer plan".
 *
 * @property {String} name The name of the topic
 * @property {function(Request): boolean} requestMatches This function validates if the request matches the topic
 */
/**
 * Enumeration of supported topics.
 *
 * @property {Topic} LOCATION The location topic.
 */
export const Topics = Object.freeze({
  LOCATION: {
    name: 'locationTopic',
    requestMatches: (request) => request && request.method.startsWith('locationEvents'),
  },
  TRANSFER_PLAN: {
    name: 'transferPlanTopic',
    requestMatches: (request) =>
      request && ['updateTransferPlan', 'deleteTransferPlan'].includes(request.method),
  },
});

/**
 * @typedef {function(): void} UnSubscribe
 * Unsubscribe from a topic.
 */
/**
 * @typedef {function(Topic, number): UnSubscribe} Subscribe
 * Subscribes the user to a certain topic.
 *
 * @param {Topic} topic The topic to subscribe to.
 * @param {number} uniqueId An identifier for the subscription.
 * @return {UnSubscribe} Function to unsubscribe.
 */
/**
 * @typedef {function(): void} RemoveTopicProviderListener
 * This function removes the listener that was added.
 */
/**
 * @typedef {function(Topic, WebSocketListener): RemoveTopicProviderListener} AddTopicProviderListener
 * Adds the listener that processes messages for the given topic and returns a function that can remove the listener.
 *
 * @param {Topic} topic The topic to listen to.
 * @param {WebSocketListener} webSocketListener The function that should be executed.
 * @return {RemoveTopicProviderListener} The function to remove the listener.
 */
/**
 * @typedef TopicContextValue
 * This object gives users the possibility to listen to events of the given topic.
 * The subscription to the topic itself is done on mount of the provider.
 *
 * @property {Subscribe} subscribeToTopic Subscribes the user to a certain topic.
 * @property {AddTopicProviderListener} addListenerForTopic Add a listener to the web socket. The listener will only process data of the current topic.
 */
/**
 * @type {React.Context<TopicContextValue>}
 */
const TopicContext = createContext(undefined);
TopicContext.displayName = 'TopicContext';

/**
 * The hook that creates the {@link TopicContextValue}.
 *
 * @return {TopicContextValue}
 */
export const useTopicContextProviderState = () => {
  const { webSocketProxy } = useWebSocketContext();
  const { addListener: addWebSocketListener } = useWebSocketListeners();
  const subscribedTopics = useRef([]);

  const subscribeToTopic = useCallback(
    (topic, uniqueId) => {
      if (subscribedTopics.current.includes(topic)) {
        return noop;
      }

      const subscribe = () => {
        webSocketProxy.send(`{ "method":"${topic.name}.subscribe","id":${uniqueId} }`);
        subscribedTopics.current.push(topic);
      };

      executeAfterRender(() => {
        // FIXME https://jira.ebo-enterprises.com/browse/OMPTRACK-96
        //  Using a "private" member to know if the socket is open or not.
        //  The "readyState" of WebSocketProxy returns "connecting" when in fact the connection is already made.
        if (webSocketProxy._websocket.readyState !== WebSocket.OPEN) {
          webSocketProxy.once('open', subscribe);
        } else {
          subscribe();
        }
      });

      const unsubscribe = () => {
        const indexOfTopic = subscribedTopics.current.indexOf(topic);
        subscribedTopics.current.splice(indexOfTopic, 1);

        webSocketProxy.send(`{ "method":"${topic.name}.unsubscribe","id":${uniqueId} }`);
      };

      return () => unsubscribe();
    },
    [webSocketProxy]
  );

  const addListenerForTopic = useCallback(
    (topic, listener) => {
      const wrappedListener = (request) => {
        if (topic.requestMatches(request)) {
          listener(request);
        }
      };

      const removeListener = addWebSocketListener('message', wrappedListener);
      return () => removeListener();
    },
    [addWebSocketListener]
  );

  return {
    subscribeToTopic,
    addListenerForTopic,
  };
};

// ----------------------------------------------
// Provider
// ----------------------------------------------

/**
 * The provider of the {@link TopicContextValue}.
 *
 * @param {JSX} children
 */
export const TopicContextProvider = ({ children }) => {
  const state = useTopicContextProviderState();

  return <TopicContext.Provider value={state}>{children}</TopicContext.Provider>;
};

// ----------------------------------------------
// Hook
// ----------------------------------------------

/**
 * @typedef {function(): void} RemoveTopicListener
 * This function removes the listener that was added.
 */
/**
 * @typedef {function(WebSocketListener): RemoveTopicListener} AddTopicListener
 * Adds the listener that processes messages for this topic and returns a function that can remove the listener.
 *
 * @param {WebSocketListener} webSocketListener The function that should be executed.
 * @return {RemoveTopicListener} The function to remove the listener.
 */
/**
 * @typedef TopicSpecificContextValue
 *
 * @property {AddTopicListener} addListener Function to add a listener to the topic
 */
/**
 * A wrapper hook to consume the {@link TopicContextValue}.
 *
 * <p>Using this hook automatically subscribes you to the given topic.</p>
 *
 * @param {Topic} topic The topic to use.
 * @return TopicSpecificContextValue
 */
export const useTopic = (topic) => {
  const uniqueId = useUniqueId('');
  const { subscribeToTopic, addListenerForTopic } = useContext(TopicContext);

  useEffect(() => {
    const unsubscribe = subscribeToTopic(topic, uniqueId);
    return () => unsubscribe();
  }, [subscribeToTopic, topic, uniqueId]);

  const addListener = useCallback((listener) => addListenerForTopic(topic, listener), [
    addListenerForTopic,
    topic,
  ]);

  return {
    addListener,
  };
};
