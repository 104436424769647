import { useEffect, useRef, useState } from 'react';

/**
 * <p>Calculates the next movement and schedules a re-render where the movement after that
 * is returned. This loops until there's no more movement left.
 * <br />
 * Note that if no more movement is made after the last one, we keep showing the last
 * movement. This is because the captain will likely only look at this data at the start and/or
 * the end of the transfer. So if a person is joining/leaving the transfer, he needs to know.</p>
 *
 * <p>For instance, take this timeline of movements and say it's now 12:00.
 * <pre>
 *                    movement A          movement B          movement C
 * ---|-------------------|-------------------|-------------------|---    ....
 *  12:00               13:00               14:00               15:00
 *  show A            show B              show C              show C      ....
 * </pre>
 *
 * <ul>
 *   <li>At 12:00, show the next movement planned, A, and schedule a re-render at 13:00.</li>
 *   <li>At 13:00, show the next movement planned, B, and schedule a re-render at 14:00.</li>
 *   <li>At 14:00, show the next movement planned, C, and but don't schedule a re-render.</li>
 * </ul>
 * </p>
 *
 * @param {Array<TransferPlanMovement>} movements The movements of a person, sorted by time
 * @return {TransferPlanMovement | undefined} This hook returns the next "movement" that is going to happen.
 */
export const useNextFutureMovement = (movements = []) => {
  const [closestFutureMovement, setClosestFutureMovement] = useState(() =>
    getClosestFutureMovement(movements)
  );

  const futureMovementTimeout = useRef(null);

  useEffect(() => {
    if (!closestFutureMovement) {
      return () => {
        clearTimeout(futureMovementTimeout.current);
      };
    }

    const triggerDelayMs = closestFutureMovement.millis - new Date().getTime();

    futureMovementTimeout.current = setTimeout(() => {
      const newClosestFutureMovement = getClosestFutureMovement(movements);
      setClosestFutureMovement(newClosestFutureMovement);
    }, triggerDelayMs);

    return () => {
      clearTimeout(futureMovementTimeout.current);
    };
  }, [movements, closestFutureMovement]);

  return closestFutureMovement;
};

/**
 * <p>Returns the closest movement in the future.</p>
 *
 * <p>If no more future movements are made, show the last one. The captain will probably
 * only look once in a while or at the end of the transfer at this information. He then
 * needs to know the last status. For instance, somebody is leaving, we don't need to wait
 * for this person.</p>
 *
 * @param {Array<TransferPlanMovement>} sortedMovements Movements, sorted by time.
 */
export const getClosestFutureMovement = (sortedMovements = []) => {
  const nowInMillis = new Date();
  const futureMovements = sortedMovements.filter((movement) => movement.millis > nowInMillis);

  if (futureMovements.length > 0) {
    return futureMovements.sort(createMovementComparator(nowInMillis))[0];
  }

  if (sortedMovements.length > 0) {
    return sortedMovements[sortedMovements.length - 1];
  }

  return null;
};

/**
 * A factory function that creates a comparator for transfer plan movements
 * based on the given time. This to prevent re-creation of "now".
 *
 * @see compareMovementsClosestToNow
 */
const createMovementComparator = (now) => (firstMovement, secondMovement) =>
  compareMovementsClosestToNow(now, firstMovement, secondMovement);

/**
 * Sort the occurrence of the movements. The movements closest to "now" first.
 */
const compareMovementsClosestToNow = (now, firstMovement, secondMovement) => {
  if (!(firstMovement || secondMovement)) {
    return 0;
  }

  const firstMillis = firstMovement.millis;
  const secondMillis = secondMovement.millis;

  const distance_a = Math.abs(now - firstMillis);
  const distance_b = Math.abs(now - secondMillis);
  return distance_a - distance_b; // sort a before b when the distance is smaller
};
