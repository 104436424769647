/**
 * This function will return one of the possible boarding states that are used
 * for style classes to visualises a persons intention & state.
 *    planned-boarded --> e.g. Green
 *    planned-not-boarded --> e.g. Grey
 *    unplanned-boarded --> e.g. Orange
 *    unplanned-not-boarded --> Light orange
 *
 * @param {Location} locationOfInterest
 * @param {Person} personObjectFromTransferPlan A person object
 * @param {boolean} isPlanned Is the person planned for the location
 * @return {string} The boarding state
 */
export const getPersonBoardingState = (
  locationOfInterest,
  personObjectFromTransferPlan,
  isPlanned = !personObjectFromTransferPlan.unplanned
) => {
  const intention = isPlanned ? 'planned' : 'unplanned';
  const state =
    locationOfInterest?.locationId &&
    personObjectFromTransferPlan?.currentLocation?.locationId &&
    locationOfInterest.locationId === personObjectFromTransferPlan.currentLocation.locationId
      ? 'boarded'
      : 'not-boarded';

  return `${intention}-${state}`;
};
