import { useGetRequest } from '../useAxiosRequests';

//////////////////////////////////////////////
// Types for documentation
//////////////////////////////////////////////

/* eslint-disable-next-line no-unused-vars */
import { UseQueryResult } from 'react-query';

//////////////////////////////////////////////
// Variables
//////////////////////////////////////////////

const url = '/omp-service-tracking/authorization';

//////////////////////////////////////////////
// Hooks
//////////////////////////////////////////////

/**
 * @typedef TrackingAuthorization
 *
 * Provides the user with information regarding his authorization within the app.
 *
 * @property {Boolean} view Does the person have the rights to view content
 * @property {Boolean} edit Does the person have the rights to edit content
 */
/**
 * Requests the {@link TrackingAuthorization} from the API.
 * Keep in mind that this object is read-only.
 *
 * @returns {UseQueryResult<TrackingAuthorization, ?>}
 */
const useTrackingAuthorization = () =>
  useGetRequest({
    queryKey: ['trackingAuthorization'],
    url,
    options: {
      select: ({ data }) => Object.freeze(data),
    },
  });

//////////////////////////////////////////////

export { useTrackingAuthorization as default };
