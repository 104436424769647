/**
 * Maps all the people found on the transfer plan. These are the team members but also people
 * on other offshore locations.
 *
 * @param {TransferPlan} transferPlan
 * @param {Array<PersonNearVehicle>} peopleNearVehicle
 * @return {Array<Person>}
 */
const getAllPersonsFromTransferPlan = ({ transferPlan, peopleNearVehicle = [] }) => {
  if (!transferPlan) {
    return [];
  }

  const personById = new Map();

  const teamMembers = transferPlan.teams.flatMap((team) => team.members);

  // If a person is in both lists ("team members" and "people near vehicle"),
  // give priority to the "team members"
  const personIdsFromTransferPlan = teamMembers.map((teamMember) => teamMember.personId);
  const nonTeamMembersNearVehicle = peopleNearVehicle.filter(
    (personNearVehicle) => !personIdsFromTransferPlan.includes(personNearVehicle.personId)
  );

  const all = teamMembers.concat(nonTeamMembersNearVehicle);

  all.forEach((person) => {
    personById.set(person.personId, person);
  });

  return Array.from(personById.values());
};

export { getAllPersonsFromTransferPlan };
