import React, { createContext, memo, useCallback, useContext, useRef, useState } from 'react';

// ------------------------------------
// Variables
// ------------------------------------
/**
 * @typedef SelectedPersonResult
 * The resulting object of the {@link SelectedPersonContext}. It holds the currently selected
 * person along with handlers to select or remove.
 *
 * @property {Object} selectedPerson The currently selected person.
 * @property {function(person): void} selectPerson Function to select a person.
 * @property {function(): void} resetSelectedPerson Removes the currently selected person.
 */
/**
 *
 * @type {React.Context<SelectedPersonResult | null>}
 */
const SelectedPersonContext = createContext(null);
const TIMEOUT_IN_MS = 30_000;

// ------------------------------------
// Hook
// ------------------------------------

/**
 * <p>A custom hook that contains the logic for selecting/clearing a person.
 * This hook also clears the selected person after a certain amount of time.</p>
 *
 * @return SelectedPersonResult
 */
const useSelectedPersonState = (initialPerson) => {
  const [selectedPerson, setSelectedPerson] = useState(initialPerson);
  const hideDetailTimeoutRef = useRef(null);

  const resetSelectedPerson = useCallback(() => {
    clearTimeout(hideDetailTimeoutRef.current);
    setSelectedPerson(null);
    hideDetailTimeoutRef.current = null;
  }, []);

  const selectPerson = useCallback(
    (person) => {
      if (person) {
        clearTimeout(hideDetailTimeoutRef.current);
        setSelectedPerson(person);
        hideDetailTimeoutRef.current = setTimeout(() => {
          resetSelectedPerson();
        }, TIMEOUT_IN_MS);
      } else {
        resetSelectedPerson();
      }
    },
    [resetSelectedPerson]
  );

  return {
    selectedPerson,
    selectPerson,
    resetSelectedPerson,
  };
};

// --------------------------------------
// Provider
// --------------------------------------

/**
 * The provider that holds the state and passes it as the {@link SelectedPersonResult}.
 */
const SelectedPersonContextProviderBase = ({ initialPerson, children }) => {
  const result = useSelectedPersonState(initialPerson);

  return <SelectedPersonContext.Provider value={result}>{children}</SelectedPersonContext.Provider>;
};

export const SelectedPersonContextProvider = memo(SelectedPersonContextProviderBase);

// --------------------------------------
// Hook
// --------------------------------------

/**
 * This hook subscribes to the {@link SelectedPersonContext}.
 *
 * @return {SelectedPersonResult | null}
 */
export const useSelectedPerson = () => {
  return useContext(SelectedPersonContext);
};
