import { default as Generic } from './Generic.json';
import { default as Header } from './Header.json';
import { default as Location } from './Location.json';
import { default as Person } from './Person.json';
import TransferPlan from './TransferPlan.json';

export default {
  header: Header,
  location: Location,
  person: Person,
  transferPlan: TransferPlan,
  ...Generic,
};
