import { useMemo } from 'react';
import { getPersonTypeDefaultImage } from '../../../../components/image/defaultPersonImages/DefaultPersonImages';
import { useEntityImageUri } from './useEntityImageUri';

// ------------------------------------------------
// Hooks
// ------------------------------------------------

/**
 * Retrieves the photo of the person with the corresponding ID and converts it into a Base64 encoded String.
 * If no photo is available, a default image will be used instead.
 *
 * @param {number} personId
 * @param {string} personType
 *
 * @return {string}
 */
const usePersonImageUri = ({ personId, personType }) => {
  const defaultImage = useMemo(() => getPersonTypeDefaultImage(personType), [personType]);

  return useEntityImageUri({
    entityName: 'person',
    entityId: personId,
    defaultImage,
  });
};

export { usePersonImageUri };
