import React, { createContext, useContext } from 'react';
import { useTransferPlanListOfToday } from '../../data/api/hooks/useTransferPlanListOfToday/useTransferPlanListOfToday';
import LoadingAsOverlay from '../../data/loading/LoadingAsOverlay';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

// -------------------------------------------------
// Variables
// -------------------------------------------------

/** Message used in loading icon to indicate that data from the transferPlan is being fetched. */
const gettingTransferDataMessage = translate('data.gettingTransferData');

/**
 * @typedef TransferPlanListOfToday
 * An object that holds the list of transfer plans for today.
 *
 * @property {Array<TransferPlan>} transferPlans List of all the possible transfer plans.
 */
/**
 * @type {React.Context<TransferPlanListOfToday>}
 */
const TransferPlanListOfTodayContext = createContext(undefined);
TransferPlanListOfTodayContext.displayName = 'TransferPlanListOfTodayContext';

// -------------------------------------------------
// Provider
// -------------------------------------------------

/**
 * Provides the transfer plan state to it's consumers.
 *
 * @see TransferPlanListOfToday
 */
const TransferPlanListOfTodayContextProvider = ({ children }) => {
  const { isLoading, isError, error, data: listOfToday } = useTransferPlanListOfToday();

  if (isLoading) {
    return <LoadingAsOverlay messageTranslated={gettingTransferDataMessage} />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <TransferPlanListOfTodayContext.Provider value={listOfToday}>
      {children}
    </TransferPlanListOfTodayContext.Provider>
  );
};

// -------------------------------------------------
// Hook
// -------------------------------------------------

/**
 * A hook that returns the transfer plan state.
 *
 * @throws {Error} When no value is provided.
 * @return {TransferPlanListOfToday}
 */
const useTransferPlanStateContext = () => {
  const transferPlanStateContextValue = useContext(TransferPlanListOfTodayContext);

  if (!transferPlanStateContextValue) {
    throw Error('Wrap the component with a TransferPlanListOfTodayContextProvider');
  }

  return transferPlanStateContextValue;
};

export { TransferPlanListOfTodayContextProvider, useTransferPlanStateContext };
