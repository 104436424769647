import * as PropTypes from 'prop-types';
import { locationPropTypes } from './Location';
import { companyPropTypes } from './Company';
import { transferPlanMovementPropType } from './TransferPlanMovement';

/**
 * @typedef PersonType
 */
export const PersonTypes = {
  BASIC: 'BASIC',
  VISITOR: 'VISITOR',
  OFFSHORE_WORKER: 'OFFSHORE_WORKER',
  VEHICLE_CREW: 'VEHICLE_CREW',
  /**
   * A person from another transfer plan can picked up by the vessel and he would just be a passerby.
   * In this case, the person won't appear on the transfer plan. Let's just mark this type as "other".
   */
  OTHER: 'OTHER',
};

/**
 * @typedef Person
 *
 * @property {Number} personId
 * @property {String} name
 * @property {String} nok
 * @property {PersonType} type
 * @property {boolean} internal
 * @property {Array<Team>} teams
 * @property {Array<WorkPermit>} permits
 * @property {Location} previousLocation
 * @property {Location} currentLocation
 * @property {String} boardingState
 * @property {Company} company
 * @property {Number} tppId
 * @property {boolean} unplanned
 * @property {boolean} disembarked
 * @property {boolean} currentlyOnTransferPlanVessel
 * @property {Array<TransferPlanMovement>} movements
 */
/** An object that contains the data from a person object. */
export const personPropTypes = {
  /** The person's unique identifier. */
  personId: PropTypes.number,
  /** The full name of this person. */
  name: PropTypes.string,
  /** The unique identification (nok) number of this person. */
  nok: PropTypes.string,
  /** The type / function that a person can represent. */
  type: PropTypes.oneOf(Object.values(PersonTypes)),
  /** Boolean that indicates weather this person is internal or not. */
  internal: PropTypes.bool,
  /** An array that contains all the teams where this person is a member of. */
  teams: PropTypes.array,
  /** An array that contains all the work permit numbers where this person is linked to. */
  permits: PropTypes.array,
  /** An object that contains the information about the previous location. */
  previousLocation: PropTypes.shape(locationPropTypes),
  /** An object that contains the information about the current location. */
  currentLocation: PropTypes.shape(locationPropTypes),
  /** The status from a pearson weather he's boarded on a specific location eg: boarded, unplanned etc. */
  boardingState: PropTypes.oneOf([
    'planned-boarded',
    'unplanned-boarded',
    'planned-not-boarded',
    'unplanned-not-boarded',
  ]),
  /** The name of the company that this person represents/ works in. */
  company: PropTypes.shape(companyPropTypes),
  /** The transfer plan person identifier */
  tppId: PropTypes.number,
  /** Indicates whether the person was planned for this transfer or not. */
  unplanned: PropTypes.bool,
  /** Indicates whether the person is currently on the transfer plan vessel or not. */
  currentlyOnTransferPlanVessel: PropTypes.bool,
  /** Indicates whether the person has disembarked the current transfer plan vehicle. */
  disembarked: PropTypes.bool,
  /**
   * Movements of a person during the transfer plan.
   */
  movements: PropTypes.arrayOf(PropTypes.shape(transferPlanMovementPropType)),
};
