import React, { memo, useCallback, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import IconWrapper from '../../image/icon/IconWrapper';

import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import './UserMenu.scss';
import { useDisembarkAllConfirmationContext } from '../DisembarkAllConfirmationPopup/context/DisembarkAllConfirmationContext';
import { useSelectedTransferPlanContext } from '../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

const { getElementClassName } = BEMClassNameUtils('user-menu');
const logoutLabel = translate('authentication.logout');
const applicationNameLabel = translate('application.name');

//-----------------------------------------------------
// Components
//-----------------------------------------------------

const UserMenuBase = () => {
  const { openOrExecute } = useDisembarkAllConfirmationContext();
  const { setSelectedTransferPlan } = useSelectedTransferPlanContext();
  const {
    keycloak: {
      tokenParsed: { name },
      logout,
    },
  } = useKeycloak();

  const [isOpen, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((currentlyOpen) => !currentlyOpen), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleLogout = useCallback(() => {
    openOrExecute(() => {
      // This makes sure that the URL doesn't contain the currently selected TP which would be loaded when logging back in.
      setSelectedTransferPlan(undefined);
      logout();
    });
  }, [openOrExecute, setSelectedTransferPlan, logout]);

  return (
    <>
      <div
        data-testid={'header-user'}
        className={getElementClassName('icon-wrapper', 'user')}
        onClick={handleToggle}
      >
        <IconWrapper idPrefix={'header'} iconName={'user-alt'} />
      </div>

      {isOpen && (
        <>
          <div className={getElementClassName('content')}>
            <div className={getElementClassName('content-group')}>
              <div data-testid={'header-user__user-name'} className={getElementClassName('user')}>
                {name}
              </div>
            </div>
            <div className={getElementClassName('content-group')}>
              <IconWrapper idPrefix={'logout'} iconName={'sign-out-alt'} />
              <div
                data-testid={'header-user__logout'}
                className={getElementClassName('logout')}
                onClick={handleLogout}
              >
                {logoutLabel}
              </div>
            </div>
            <div className={getElementClassName('content-group')}>
              <div data-testid={'header-user__version'} className={getElementClassName('version')}>
                {applicationNameLabel} {process.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
          <div className={getElementClassName('overlay')} onClick={handleClose} />
        </>
      )}
    </>
  );
};

export const UserMenu = memo(UserMenuBase);
