import * as PropTypes from 'prop-types';

export const LocationType = {
  VEHICLE: 'VEHICLE',
  WIND_TURBINE: 'WIND_TURBINE',
};

/**
 * @typedef Location
 *
 * @property {String} id The fabricated ID of the location, contains the type
 * @property {String} name The location's name.
 * @property {Number} lastUpdateMillis The timestamp of in millis indicating when the tracking event took place.
 */
/** An object that contains the data from a location object. */
export const locationPropTypes = {
  /** The name of the location. */
  name: PropTypes.string,
  /** The timestamp in milliseconds that indicates when the tracking event took place. */
  lastUpdateMillis: PropTypes.number,
  /** The unique location identifier. */
  id: PropTypes.number,
  /**
   * The type of the location
   */
  type: PropTypes.string,
  /**
   * The location ID as a string
   */
  locationId: PropTypes.string,
};
