import './PersonCardPlaceHolder.scss';

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import PersonCardPictureAndType from '../../../person/PersonCard/PersonCardPictureAndType/PersonCardPictureAndType';
import { usePersonImageUri } from '../../../../data/api/hooks/useEntityImageUri/usePersonImageUri';
import { personPropTypes } from '../../../../model/Person';
import { translate, translateFormat } from 'raf-core-react/dist/utils/localization/Translations';

const cardsHidden = translate('personCardPlaceholder.hidden');

/**
 * Represents a the place holder of a PersonCard, it shows the amount of items
 * that are remaining in the array of displayable objects
 * @param {Object} person - any person object, it is used for the image and an ID
 * @param {Number} remainingCardNr - The amount of hidden person cards
 * @param {Function} onPersonCardClick - Callback function when clicking the placeholder card (toggle hide/show)
 * @param {Bool} toggleCards - The state that the card is in now, true shows the remaining cards, false hides them
 */
export const PersonCardPlaceHolder = ({
  person,
  remainingCardNr,
  onPersonCardClick,
  toggleCards,
}) => {
  const personImageUri = usePersonImageUri(person);

  const handlePersonCardClick = useCallback(() => {
    onPersonCardClick();
  }, [onPersonCardClick]);

  return (
    <div
      data-cy="person-placeholder-card"
      className={'person-placeholder-card'}
      onClick={handlePersonCardClick}
    >
      <PersonCardPictureAndType personType={person.type} personImageUrl={personImageUri} />
      <p className="person-placeholder-card__title">
        {toggleCards
          ? translateFormat('personCardPlaceholder.visible', { remainingCardNr: remainingCardNr })
          : cardsHidden}
      </p>
    </div>
  );
};

PersonCardPlaceHolder.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes).isRequired,
  remainingPersons: PropTypes.number,
  /** The function that describes what should happen with the card if a user clicks on it */
  onPersonCardClick: PropTypes.func,
  toggleCards: PropTypes.bool,
  maxShownCards: PropTypes.number,
};

export default memo(PersonCardPlaceHolder);
