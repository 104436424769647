import React, { memo } from 'react';

import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { useActionForPerson } from './hooks/useActionForLocation/useActionForPerson';

import './PersonDetailActions.scss';
import { useAuthorizationContext } from '../../../../contexts/AuthorizationContext/AuthorizationContext';
import { Button } from 'ebo-react-component-library/dist/javascript/components/input/button/Button';

// ------------------------------------
// Variables
// ------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-detail-actions');
const blockClassName = getBlockClassName();

// ------------------------------------
// Components
// ------------------------------------

/**
 * This component resembles the action a person can make.
 *
 * <p>The action is based on the possible locations. If only one location is available, the
 * request is made immediately. Otherwise a popup will opened to select a location.
 */
const PersonDetailActionsBase = ({ locationOfInterest }) => {
  const { label, submitRequest } = useActionForPerson(locationOfInterest);
  const { edit } = useAuthorizationContext();

  if (!label) {
    return null;
  }

  return (
    <div className={blockClassName}>
      <Button
        id={'personDetailAction'}
        label={label}
        className={getElementClassName('action')}
        isBold={false}
        onClick={submitRequest}
        isDisabled={!edit}
      />
    </div>
  );
};

export const PersonDetailActions = memo(PersonDetailActionsBase);
