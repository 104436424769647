import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { cloneDeep } from 'lodash-es';

import { useSelectedTransferPlanContext } from '../../../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';

// --------------------------------
// Hooks
// --------------------------------

/**
 * Provides a function that handles the "optimistic update" of a location update (triggered by the {@link useEventSubmission} or {@link useDisembark}).
 *
 * The current transfer plan is updated with the "new location" for the people mentioned in the request.
 *
 * @return {(function(Array<TrackingEventRequest>): Promise<void>)} A function that updates the locations
 * @see <a href="https://react-query.tanstack.com/guides/optimistic-updates">React-Query: Optimistic updates</a>
 */
export const useOptimisticLocationUpdateHandler = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const queryClient = useQueryClient();

  const transferPlanNumber = selectedTransferPlan?.name;
  const transferPlanStateQueryKey = useMemo(() => ['transferPlan', transferPlanNumber], [
    transferPlanNumber,
  ]);

  return useCallback(
    async (requests) => {
      await queryClient.cancelQueries(transferPlanStateQueryKey);

      const transferPlanStateResponse = queryClient.getQueryData(transferPlanStateQueryKey);

      // Clone because the "useMemo" would still keep the same reference
      /** @type TransferPlan */
      const transferPlanState = cloneDeep(transferPlanStateResponse.data);

      const { teams = [], otherOffshorePersonsLocations = [], vehicle } = transferPlanState;

      const transferPlanPeople = teams.flatMap((team) => team.members);
      const nonTransferPlanPeople = otherOffshorePersonsLocations.flatMap(
        (locationWithNonTpp) => locationWithNonTpp.nonTransferPlanPersons
      );

      const allPeople = transferPlanPeople.concat(nonTransferPlanPeople);

      requests.forEach((request) => {
        const person = allPeople.find((person) => person.personId === request.personId);

        person.previousLocation = person.currentLocation;
        person.currentLocation = {
          ...request.toLocation,
          lastUpdateMillis: request.timestamp,
        };

        if (person.currentLocation) {
          person.currentlyOnTransferPlanVessel =
            person.currentLocation.locationId === vehicle.locationId;
        } else {
          // No "to location" so the "disembark" has been called.
          person.currentlyOnTransferPlanVessel = false;
          person.disembarked = true;
        }
      });

      queryClient.setQueryData(transferPlanStateQueryKey, {
        ...transferPlanStateResponse,
        data: transferPlanState,
      });
    },
    [queryClient, transferPlanStateQueryKey]
  );
};
