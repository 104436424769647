import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

// ------------------------------------
// Variables
// ------------------------------------

const HASH_CHARACTER = '#';

// ------------------------------------
// Exports
// ------------------------------------

/**
 * Given a hash string, strip away the hash character from the string.
 * @param {String} hash
 * @return {string} The hashless string
 */
export const stripHashCharacter = (hash) =>
  hash.substr(
    hash.indexOf(HASH_CHARACTER) + HASH_CHARACTER.length,
    hash.length - HASH_CHARACTER.length
  );

/**
 * @typedef HashParamsResult
 * The result of the {@link useHashParams} hook. It carries utilities functions concerning
 * the location's hash property.
 *
 * @property {function(String): String} get Get the data for the given key.
 * @property {function(String, String): void} push Updates the hash with the provided data. A new "entry"
 * is created when needed. The new hash is immediately pushed on to the location.
 * @property {function(String): void} remove Remove the parameter for the given key. The new hash
 * is immediately pushed on to the location.
 */
/**
 * A hook where users can retrieve but also update the location's hash property.
 * @return HashParamsResult
 */
export const useHashParams = () => {
  const history = useHistory();
  const location = useLocation();

  const { hash } = location;

  const hashData = stripHashCharacter(hash);

  const searchParams = useMemo(() => new URLSearchParams(hashData), [hashData]);

  const get = useCallback((key) => searchParams.get(key), [searchParams]);

  const push = useCallback(
    (key, value) => {
      searchParams.set(key, value);

      history.push({
        hash: searchParams.toString(),
      });
    },
    [history, searchParams]
  );

  const remove = useCallback(
    (key) => {
      searchParams.delete(key);

      history.push({
        hash: searchParams.toString(),
      });
    },
    [history, searchParams]
  );

  return {
    get,
    push,
    remove,
  };
};
