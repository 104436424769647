import './PersonDetailBody.scss';

import React from 'react';
import { getPersonBoardingState } from '../../utils/PersonUtils';
import PropTypes from 'prop-types';
import { getBoardingStateName } from '../../../utils/BoardingStateModifiers';
import IconWrapper from '../../../image/icon/IconWrapper';
import { personPropTypes } from '../../../../model/Person';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import PersonDetailBodyLocation from './PersonDetailBodyLocation/PersonDetailBodyLocation';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { PersonDetailActions } from '../PersonDetailActions/PersonDetailActions';
import { locationPropTypes } from '../../../../model/Location';
import { usePersonPlannedForLocation } from '../../../../utils/hooks/usePersonPlannedForLocation/usePersonPlannedForLocation';

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-card-detail-body');

const lastKnownLocation = translate('location.label.lastKnown');
const unavailableLocationInfo = translate('location.unavailable.locationInfo');
const workPermits = translate('person.workPermits');
const teams = translate('person.teams');
const unavailableWorkPermits = translate('person.unavailable.workPermits');

export default function PersonDetailBody({ person, locationOfInterest }) {
  const isPlanned = usePersonPlannedForLocation({
    person,
    location: locationOfInterest,
  });

  const personBoardingState = getPersonBoardingState(locationOfInterest, person, isPlanned);

  return (
    <div className={getBlockClassName()}>
      <div className={getElementClassName('top', personBoardingState)}>
        {getBoardingStateName(personBoardingState)}
      </div>
      <div className={getElementClassName('list')}>
        <div>
          <div className={getElementClassName('list-header')}>
            <IconWrapper
              idPrefix={getBlockClassName()}
              iconName={'map-marker-alt'}
              className={getElementClassName('icon')}
            />
            <div>{lastKnownLocation}</div>
          </div>
          <div className={getElementClassName('locations')}>
            {person.currentLocation !== undefined ? (
              <PersonDetailBodyLocation
                locationTrackingEventTimestampMs={person.currentLocation.lastUpdateMillis}
                locationName={person.currentLocation.name}
              />
            ) : (
              ''
            )}
            {person.previousLocation !== undefined ? (
              <PersonDetailBodyLocation
                locationTrackingEventTimestampMs={person.previousLocation.lastUpdateMillis}
                locationName={person.previousLocation.name}
              />
            ) : (
              ''
            )}
            {person.currentLocation === undefined &&
              person.previousLocation === undefined &&
              unavailableLocationInfo}
          </div>
        </div>
        {person.permits !== undefined && person.permits.length !== 0 && (
          <div>
            <div className={getElementClassName('list-header')}>
              <IconWrapper
                idPrefix={getBlockClassName()}
                iconName={'file'}
                className={getElementClassName('icon')}
              />
              <div>{workPermits}</div>
            </div>
            <div className={getElementClassName('permits')}>
              {person.permits
                ? person.permits.map((permit, index) => (
                    <div
                      key={'person-card-detail' + permit}
                      className={getElementClassName('list-item')}
                    >
                      <div className={getElementClassName('permit')}>{person.permits[index]}</div>
                    </div>
                  ))
                : unavailableWorkPermits}
            </div>
          </div>
        )}
        {person.teams !== undefined && person.teams.length !== 0 && (
          <div>
            <div className={getElementClassName('list-header')}>
              <IconWrapper
                idPrefix={getBlockClassName()}
                iconName={'user-friends'}
                className={getElementClassName('icon')}
              />
              <div>{teams}</div>
            </div>
            <div className={getElementClassName('teams')}>
              {person.teams.map((team) => (
                <div key={'person-card-detail' + team} className={getElementClassName('list-item')}>
                  <div className={getElementClassName('team')}>{team}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <PersonDetailActions locationOfInterest={locationOfInterest} />
    </div>
  );
}

PersonDetailBody.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  locationOfInterest: PropTypes.shape(locationPropTypes),
};
