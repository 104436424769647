import { useGetRequest } from '../useAxiosRequests';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { useQueryClient, UseQueryResult } from 'react-query';
import { getOrDefaultRefetchInterval, getOrDefaultStaleTime } from '../../config';

// ---------------------------------------
// Variables / functions
// ---------------------------------------

/**
 * The prefix for the query key of automatic logged events.
 *
 * @type {[string, string]}
 */
const automaticLoggedEventsQueryKeyPrefix = ['loggedEvent', 'automatic'];

/**
 * Only events from up to 30 minutes are fetched
 * @type {number}
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 30);
/**
 * Refetch every 30 minutes
 * @type {0|number}
 */
const REFETCH_INTERVAL = getOrDefaultRefetchInterval({
  productionIntervalMs: 1000 * 60 * 30,
  devIntervalMs: 1000 * 30,
});

// ---------------------------------------
// Hook
// ---------------------------------------

/**
 * @typedef TrackingEventDuration
 * Represents a duration of a {@link TrackingEvent}.
 *
 * @property {number} start
 * @property {number} end
 */
/**
 * @typedef TrackingEvent
 * Represents an event that was registered on some location on some time.
 *
 * @property {Location} location
 * @property {number} timestamp
 * @property {TrackingEventDuration} duration
 */
/**
 * This hook fetches "recent" {@link TrackingEvent}s for all the people of the
 * transfer plan that matches the given ID.
 *
 * <p>The data is returned as a map with the person ID as key and a list of
 * {@link TrackingEvent}s as it's value.</p>
 *
 * @param {number} transferPlanId The transfer plan to fetch the people's events.
 * @return {UseQueryResult}
 */
export const useTransferPlanAutomaticLoggedEvents = (transferPlanId) => {
  const queryClient = useQueryClient();

  return useGetRequest({
    queryKey: automaticLoggedEventsQueryKeyPrefix.concat(['transferPlan', transferPlanId]),
    url: `/omp-service-tracking/events/logged/automatic/byTransferPlan/${transferPlanId}`,
    options: {
      enabled: !!transferPlanId,
      staleTime: CACHE_TIME,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      select: ({ data }) => data,
      onSettled: (eventsByPersonId) => {
        Object.keys(eventsByPersonId).forEach((personId) => {
          const queryKey = automaticLoggedEventsQueryKeyPrefix.concat([
            'person',
            parseInt(personId),
          ]);
          const events = eventsByPersonId[personId];

          const existingEvents = queryClient.getQueryData(queryKey);
          if (!existingEvents) {
            queryClient.setQueryData(queryKey, events);
          }
        });
      },
    },
  });
};

/**
 * This hook fetches "recent" {@link TrackingEvent}s for a person that matches
 * the given ID.
 *
 * @param personId The person who's events should be requested.
 * @return {UseQueryResult}
 */
export const usePersonAutomaticLoggedEvents = (personId) =>
  useGetRequest({
    queryKey: automaticLoggedEventsQueryKeyPrefix.concat(['person', personId]),
    url: `/omp-service-tracking/events/logged/automatic/${personId}`,
    options: {
      enabled: !!personId,
      staleTime: CACHE_TIME,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      select: ({ data }) => data,
    },
  });
