import './Header.scss';

import React, { memo } from 'react';
import PageSelection from './PageSelection/PageSelection';
import { useSelectedTransferPlanContext } from '../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';

import { translate, translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';

import { SwitchTransferPlan } from './SwitchTransferPlan/SwitchTransferPlan';
import { ConnectionIndicators } from './ConnectionIndicators/ConnectionIndicators';
import { UserMenu } from './UserMenu/UserMenu';
import { DisembarkAllConfirmationContextProvider } from './DisembarkAllConfirmationPopup/context/DisembarkAllConfirmationContext';
import { DisembarkAllConfirmationPopup } from './DisembarkAllConfirmationPopup/DisembarkAllConfirmationPopup';
import { DefaultDisembarkAllMessageProvider } from './DisembarkAllConfirmationPopup/context/DisembarkAllMessageContext';
import { useCustomerLogo } from '../../data/api/hooks/useCustomerLogo/useCustomerLogo';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('header');

const customerLogoAltLabel = translate('header.logo.alt');

//-----------------------------------------------------
// Components
//-----------------------------------------------------

// TODO Split transfer plan info, etc. into smaller components
/**
 * This component holds some data about the network state, the
 * current transfer plan, provides some actions like selecting
 * a transfer plan but is also used for the navigation.
 */
const Header = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { data: logoAsEncodedString } = useCustomerLogo();

  return (
    <header className={getBlockClassName()}>
      {logoAsEncodedString && (
        <div className={getElementClassName('customer-logo')}>
          <img alt={customerLogoAltLabel} src={logoAsEncodedString} />
        </div>
      )}
      <div
        data-testid={'header-general-transfer-info'}
        className={getElementClassName('general-transfer-info')}
      >
        <div className={getElementClassName('estimate-times')}>
          {selectedTransferPlan && selectedTransferPlan.etd && (
            <div className={getElementClassName('etd')}>
              {translateFormat('header.etd', { etd: selectedTransferPlan.etd })}
            </div>
          )}

          {selectedTransferPlan && selectedTransferPlan.eta && (
            <div className={getElementClassName('eta')}>
              {translateFormat('header.eta', { eta: selectedTransferPlan.eta })}
            </div>
          )}
        </div>
        <div className={getElementClassName('transfer-name')}>
          {selectedTransferPlan &&
            `${selectedTransferPlan.vehicle.name} (${selectedTransferPlan.name})`}
        </div>
      </div>

      <PageSelection />

      <div data-testid={'header-controls'} className={getElementClassName('controls')}>
        <ConnectionIndicators />

        <DisembarkAllConfirmationContextProvider>
          <SwitchTransferPlan />
          <UserMenu />

          <DefaultDisembarkAllMessageProvider>
            <DisembarkAllConfirmationPopup />
          </DefaultDisembarkAllMessageProvider>
        </DisembarkAllConfirmationContextProvider>
      </div>
    </header>
  );
};

export default memo(Header);
