import { useSelectedTransferPlanContext } from '../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useGetRequest } from '../useAxiosRequests';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { getOrDefaultStaleTime } from '../../config';

// --------------------------------
// Variables / functions
// --------------------------------

/**
 * Activities are usually created upfront. We could cache them 1/4th of a day.
 * @type {number}
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 60 * 6);

// --------------------------------
// Hooks
// --------------------------------

/**
 * This hook returns the activities defined on the currently selected transfer plan.
 *
 * @return {UseQueryResult}
 */
export const useTransferPlanActivities = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();

  const queryKey = useMemo(() => ['transferPlanActivities', selectedTransferPlan.id], [
    selectedTransferPlan.id,
  ]);
  const queryClient = useQueryClient();

  const query = useGetRequest({
    queryKey,
    url: `/omp-service-tracking/transfer-plan/${selectedTransferPlan.id}/activities`,
    options: {
      enabled: !!selectedTransferPlan?.id,
      staleTime: CACHE_TIME,
      // Don't return the entire response, the data is enough.
      select: ({ data }) => data,
    },
  });

  /**
   * The query doesn't relaunch when the transfer plan changed. So we need to
   * trigger this manually in order to get the latest data.
   */
  useEffect(() => {
    if (selectedTransferPlan && selectedTransferPlan.id) {
      queryClient.refetchQueries(queryKey);
    }
  }, [queryClient, queryKey, selectedTransferPlan]);

  return query;
};
