/**
 * @typedef OmpTrackingConfiguration
 *
 * For production, we can configure some properties "at runtime".
 *
 * <p>This enables us to build only one image and deploy it where needed.
 * Once deployed, we can configure customer specific settings. Even
 * without restarting the container.
 *
 * @property {object} rest Holds all the info required to communicate with the Rest API
 * @property {string} rest.url The base URI used to do request.
 * @property {object} websocket Holds all the info required to connect with websocket
 * @property {string} websocket.url The URI used to connect.
 * @property {object} keycloak Holds all the info to connect to Keycloak
 * @property {string} keycloak.url The base URI used to do request.
 */
/**
 * @type OmpTrackingConfiguration
 */
let tempConfig;

try {
  // eslint-disable-next-line no-undef
  tempConfig = ompTrackExternalConfiguration;
} catch (error) {
  console.warn('The external configuration is not defined, using the default.');

  tempConfig = {
    rest: {
      url: process.env.REACT_APP_API_URL,
    },
    websocket: {
      url: process.env.REACT_APP_WEBSOCKET_URL,
    },
    keycloak: {
      url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
    },
  };
}

export const config = Object.freeze(tempConfig);

// TODO Should come from the API one day
export const trackingStatusIndicatorMaxMinutes = 60;

/**
 * Depending on the environment, return the stale time in ms.
 * @param {number} productionStaleTimeMs The production stale time
 * @return {0|number}
 */
export const getOrDefaultStaleTime = (productionStaleTimeMs) =>
  process.env.NODE_ENV === 'production' ? productionStaleTimeMs : 0;

/**
 * Depending on the environment, return the stale time in ms.
 * @param {number} productionIntervalMs The production stale time
 * @param {number} devIntervalMs The production stale time
 * @return {0|number}
 */
export const getOrDefaultRefetchInterval = ({ productionIntervalMs, devIntervalMs = undefined }) =>
  process.env.NODE_ENV === 'production' ? productionIntervalMs : devIntervalMs;
