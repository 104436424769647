import { usePersonAutomaticLoggedEvents } from '../../../../data/api/hooks/useLoggedEvents/useLoggedEvents';

// ---------------------------------------
// Variables / functions
// ---------------------------------------

export const isPersonTracked = (loggedEvents) =>
  Array.isArray(loggedEvents) && loggedEvents.length > 0;

// ---------------------------------------
// Hook
// ---------------------------------------

/**
 * Checks if a person is tracked. He's tracked when he's seen on a location using
 * "automatic (logged) events". So no manual boarding/disembarking/...
 *
 * @param {number} personId The person to validate.
 * @return {boolean}
 */
export const usePersonIsTracked = ({ personId }) => {
  const { isFetched, data: loggedEvents } = usePersonAutomaticLoggedEvents(personId);
  return isFetched && isPersonTracked(loggedEvents);
};
