import React, { memo, useCallback, useMemo, useState } from 'react';
import BEMClassNameUtils from 'ebo-react-component-library/dist/javascript/utils/BEMClassNameUtils';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

import IconWrapper from '../../../image/icon/IconWrapper';

import {
  useGatewayStatus,
  useGatewayStatusBemModifiers,
} from '../../../../data/api/hooks/useGatewayStatus/useGatewayStatus';

import './PersonsWhereaboutsGroup.scss';

// ---------------------------------------
// Variables
// ---------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('people-whereabouts-group');
const blockClassName = getBlockClassName();
const titleClassName = getElementClassName('title');
const titleContainerClassName = getElementClassName('title-container');

const unplannedLocationTitle = translate('location.unplanned');

// ---------------------------------------
// Component
// ---------------------------------------

/**
 * @typedef AmountOfPeoplePresent
 * Contains the amount of people that are present on a location. The amount is split
 * into total amount and planned
 *
 * @property {number} total
 * @property {number} planned
 */
/**
 * Is the component that makes groups of the location names
 * with the needed icons along with is.
 *
 * @param {boolean} initialOpen
 * @param {Location} location
 * @param {AmountOfPeoplePresent} amountOfPeoplePresent
 * @param {JSX} children
 */
const PersonsWhereaboutsGroup = ({
  initialOpen = false,
  location,
  amountOfPeoplePresent,
  children,
}) => {
  const [toggleDisplayClass, setToggleDisplayClass] = useState(initialOpen);

  const { data: gatewayStatus } = useGatewayStatus(location);
  const modifiers = useGatewayStatusBemModifiers(gatewayStatus);

  const toggleDisplayOfCards = useCallback(() => {
    return setToggleDisplayClass(!toggleDisplayClass);
  }, [toggleDisplayClass]);

  const groupTitle = useMemo(() => {
    if (amountOfPeoplePresent) {
      const { total, planned } = amountOfPeoplePresent;

      return `${location.name} - ${planned} (${total}) persons`;
    }
    return location.name;
  }, [amountOfPeoplePresent, location.name]);

  const additionalTitleClassNames = useMemo(
    () => (location?.unplanned ? ' ' + titleContainerClassName + '-unplanned' : ''),
    [location]
  );

  const additionalTitleAttr = useMemo(
    () => (location?.unplanned ? location.name + unplannedLocationTitle : ''),
    [location]
  );

  const additionalElementClassNames = useMemo(
    () =>
      location?.unplanned ? ' ' + getElementClassName('content-container') + '-unplanned' : '',
    [location]
  );

  return (
    <div
      data-testid={'people-whereabouts-category'}
      data-cy={location.name}
      className={blockClassName}
    >
      <div
        onClick={toggleDisplayOfCards}
        className={
          getElementClassName('content-container', modifiers) + additionalElementClassNames
        }
      >
        <div
          className={titleContainerClassName + additionalTitleClassNames}
          title={additionalTitleAttr}
        >
          <IconWrapper
            idPrefix={'people-whereabouts-group-body-locationicon'}
            iconName={'dropmarker'}
          />
          <h2 className={titleClassName}>{groupTitle}</h2>
          {gatewayStatus.supportsAutoTracking && (
            <IconWrapper
              idPrefix={'people-whereabouts-group-body-gatewayicon'}
              iconName={'gateway'}
            />
          )}
        </div>
        <IconWrapper
          className={getElementClassName('icon', toggleDisplayClass ? 'visible' : 'hidden')}
          idPrefix={'people-whereabouts-group-body'}
          iconName={'downarrow'}
        />
      </div>
      <div className={getElementClassName('content', toggleDisplayClass ? 'visible' : 'hidden')}>
        {children}
      </div>
    </div>
  );
};

export default memo(PersonsWhereaboutsGroup);
