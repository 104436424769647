import { useGetRequest } from '../useAxiosRequests';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { UseQueryResult } from 'react-query';

// ------------------------------------------------
// Variables
// ------------------------------------------------

const queryKey = 'transferPlanWhereabouts';

// ------------------------------------------------
// Hooks
// ------------------------------------------------

/**
 * @typedef PersonWhereabouts
 * This object holds the whereabouts of a person. The person can be seen on multiple locations
 * throughout the day. A timestamp indicates when he was seen on that location.
 *
 * @property {Number} personId The ID of the person
 * @property {Array<Location>} locations The locations where this person was seen (sorted by time)
 */
/**
 * A hook that fetches the {@link PersonWhereabouts} from the people on the transfer plan.
 * @return {UseQueryResult}
 */
const useTransferPlanPeopleWhereabouts = (transferplanId, data = null) => {
  const url = `/omp-service-tracking/whereabouts/byTransferPlan/${transferplanId}`;
  return useGetRequest({
    queryKey: [queryKey, transferplanId],
    url,
    data,
    options: {
      enabled: !!transferplanId,
      select: ({ data }) => {
        return data;
      },
    },
  });
};

export { useTransferPlanPeopleWhereabouts };
