import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { output } from 'ebo-react-component-library';
import CustomIcons from './CustomIcons';

/*
 * This wrapper wraps the Icon from the ebo-react-component-library
 * this wrapper will add the customIcons to the Icon component
 * */

export default class IconWrapper extends Component {
  constructor(props) {
    super(props);
    this.customIcons = CustomIcons;
  }

  render() {
    const combinedId = this.props.idPrefix.concat(`-${this.props.iconName}`);
    return (
      <output.Icon
        data-cy={combinedId}
        iconName={this.props.iconName}
        customIcons={this.customIcons}
        className={this.props.className}
        onClick={() => this.props.onClick()}
      />
    );
  }
}

IconWrapper.propTypes = {
  /** The id that is passed to the icon component inside ERCL It's concatenated with the iconName */
  idPrefix: PropTypes.string.isRequired,
  /** The name referring to the font-awesome icon or the custom icon*/
  iconName: PropTypes.string,
  /** Additional classes that will be applied on the wrapper*/
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconWrapper.defaultProps = {
  onClick: () => void 0,
};
