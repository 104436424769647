import React, { memo, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Persons from '../../../pages/persons/Persons';
import Locations from '../../../pages/locations/Locations';
import Teams from '../../../pages/teams/Teams';
import { SelectedPersonContextProvider } from '../../../contexts/SelectedPersonContext/SelectedPersonContext';

//-----------------------------------------------------
// Components
//-----------------------------------------------------

const RedirectHome = ({ toPath }) => {
  const { hash } = useLocation();
  const redirectLocation = useMemo(
    () => ({
      pathname: toPath,
      hash,
    }),
    [hash, toPath]
  );

  return <Redirect exact from={'/'} to={redirectLocation} />;
};

/**
 * Holds all the possible routes in the application.
 *
 * <p>Note: This has to be rendered inside a <code>Router</code>
 * or <code>BrowserRouter</code> component.
 */
const Routes = () => (
  <Switch>
    <Route path={'/persons'}>
      <SelectedPersonContextProvider>
        <Persons />
      </SelectedPersonContextProvider>
    </Route>
    <Route path={'/locations'}>
      <SelectedPersonContextProvider>
        <Locations />
      </SelectedPersonContextProvider>
    </Route>
    <Route path={'/teams'}>
      <Teams />
    </Route>
    <RedirectHome toPath={'/persons'} />
  </Switch>
);

const MemoRoutes = memo(Routes);

// Users of this component don't care if it's memoized or not.
//
// Preferring not to use default export as users can easily give another name
// to the variable when importing, without explicitly saying so.
// (so not using the keyword 'as')
export { MemoRoutes as Routes };
