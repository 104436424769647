import IconWrapper from '../image/icon/IconWrapper';
import React from 'react';
import PersonTypeIcon from '../utils/PersonTypeIcon';

/**
 * This will create an iconWrapper that contains the right icon to represent the different
 * person types.
 * @param {string} idPrefix - The prefix that is used to generate a unique id for the component
 * @param {string} personType - A person's type
 * @param {string} additionalClass - Additional  css styling classes that will be applied on the component
 * @return function
 */
export const getPersonTypeIconWrapper = (idPrefix, personType, additionalClass) => {
  return (
    <IconWrapper
      idPrefix={idPrefix}
      iconName={getPersonTypeIconName(personType)}
      className={additionalClass}
    />
  );
};

/**
 * This returns the right css class that represents whether the person is internal or not.
 * @param {bool} internal -  A boolean that indicates whether the person is internal or not
 * @return {string}
 */
export const getPersonBoundednessClass = (internal) => {
  if (internal) {
    return 'person-card-header--internal';
  } else {
    return 'person-card-header--external';
  }
};

/**
 * This will return the right name for the Font awesome icon that will be used to represent the different
 * person types
 * @param {string} personType - A person's type
 * @return {string}
 */
const getPersonTypeIconName = (personType) => {
  if (PersonTypeIcon[personType] === undefined) {
    return PersonTypeIcon.VISITOR;
  }
  return PersonTypeIcon[personType];
};
