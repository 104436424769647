import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelectedTransferPlanContext } from '../SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useTransferPlanPeopleContext } from '../TransferPlanPeopleContext/TransferPlanPeopleContext';
import { useTransferPlanPeopleWhereabouts } from '../../data/api/hooks/useTransferPlanPeopleWhereabouts/useTransferPlanPeopleWhereabouts';
import { getGroupedPersonsByLocation } from '../../utils/getGroupedPersonsByLocation/getGroupedPersonsByLocation';

// ----------------------------------------
// Variables
// ----------------------------------------

/**
 * @typedef PersonWhereaboutsContextResult
 *
 * @property {Map<Location, Array<Person>>} peopleByLocation People grouped by locations from the whereabouts
 * @property {function(String): Array<Person>} getPeopleForLocationId A utility function that returns the people
 * that are found, according to the whereabouts, on a location matching the given ID
 */
/**
 * @type {React.Context<PersonWhereaboutsContextResult>}
 */
const PersonWhereaboutsContext = createContext(undefined);
PersonWhereaboutsContext.displayName = 'PersonWhereaboutsContext';

/**
 * This hook returns people grouped by their location. It's based on the currently
 * selected transfer plan and fetches the whereabouts for that transfer plan.
 *
 * @return {Map<Location, Array<Person>>} People grouped by locations from the whereabouts
 */
const usePeopleByLocation = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { all } = useTransferPlanPeopleContext();
  const { data: whereabouts } = useTransferPlanPeopleWhereabouts(selectedTransferPlan?.id);

  return useMemo(() => getGroupedPersonsByLocation(whereabouts, all), [all, whereabouts]);
};

// ----------------------------------------
// Provider
// ----------------------------------------

/**
 * A provider for the {@link PersonWhereaboutsContext}.
 */
export const PersonWhereaboutsContextProvider = ({ children }) => {
  const peopleByLocation = usePeopleByLocation();

  const getPeopleForLocationId = useCallback(
    (locationId) => {
      const entries = peopleByLocation.entries();
      let currentEntry = undefined;

      while (!!(currentEntry = entries.next().value)) {
        const location = currentEntry[0];
        const people = currentEntry[1];

        if (location.locationId === locationId) {
          return people;
        }
      }

      return [];
    },
    [peopleByLocation]
  );

  return (
    <PersonWhereaboutsContext.Provider
      value={{
        peopleByLocation,
        getPeopleForLocationId,
      }}
    >
      {children}
    </PersonWhereaboutsContext.Provider>
  );
};

// ----------------------------------------
// Hooks
// ----------------------------------------

/**
 * @return {PersonWhereaboutsContextResult}
 */
export const usePersonWhereabouts = () => {
  const contextResult = useContext(PersonWhereaboutsContext);

  if (!contextResult) {
    throw Error('Wrap the component with a PersonWhereaboutsContextProvider');
  }

  return contextResult;
};
